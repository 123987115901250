import React from "react";

class PricingCard extends React.Component {
  render() {
    let type = this.props.selectedType;
    type = type.toLowerCase().split(" ")[0].trim();
    let includeText = "";
    let salePrice = this.props.item.pricing["discount"]
      ? Number(this.props.item.pricing[type]) -
        Number(this.props.item.pricing["discount"])
      : false;
    switch (this.props.item.service[0].name.toLowerCase().trim()) {
      case "express wash":
        includeText = "What's included";
        break;
      case "economy wash":
        includeText = "Express wash plus";
        break;
      case "premium wash":
        includeText = "Economy wash plus";
        break;
      case "super wash":
        includeText = "Premium wash plus";
        break;
      default:
        includeText = "Super wash plus";
        break;
    }
    console.log(this.props.item);
    return (
      <div className="pricingCard">
        <h2 className="pricingCardHeading">
          {this.props.item.service[0].name}
        </h2>
        {salePrice ? (
          <h2 className={"pricingCardText"}>
            <span className="originalPrice">
              ${this.props.item.pricing[type]}
            </span>{" "}
            ${salePrice}
          </h2>
        ) : (
          <h2 className={"pricingCardText"}>
            ${this.props.item.pricing[type]}
          </h2>
        )}

        <div
          className="bookNowButton"
          onClick={() => this.props.bookNow(this.props.item._id)}
        >
          Book Now
        </div>
        <p className="smallDesc">{this.props.item.service[0].description}</p>
        <p className="includedText">{includeText}</p>
        <ul className="includedItemsList">
          {this.props.item.service[0].includedOptions.map(function (listItem) {
            return <li className="includedItem">{listItem}</li>;
          })}
        </ul>
      </div>
    );
  }
}

export default PricingCard;
