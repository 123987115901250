import React from "react";
import { useAuth0 } from '@auth0/auth0-react';

export default function Auth0LoginButton(props) {
  const { loginWithRedirect } = useAuth0();
  const loginFunction = () => {
    loginWithRedirect();
    console.log("Login Button props: ", props)
  }
  return (
    <button 
      className="lightBlueButton" 
      onClick={() => loginFunction()}>
        Login
    </button>
  );
}