import React from "react";
import { api, googleEvent} from "../../api";
import * as Images from "../../themes/images";
import './style.css';
import HeaderMobile from "../../components/headerMobile"
import Loading from "../../components/Loading";
import ReactPixel from 'react-facebook-pixel';

class Bundle extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            availableBundles: [],
            selectedBundleId: "",
            selectedVehicle: "",
            selectedRego: "",
            errorField:{},
        }
        this.getBundles = this.getBundles.bind(this);
        this.selectBundle = this.selectBundle.bind(this);
        this.selectType = this.selectType.bind(this);
        this.validate = this.validate.bind(this);
        this.purchaseBundle = this.purchaseBundle.bind(this);
        this.setRego = this.setRego.bind(this);
    }

    validate() {
		const errors = {};
		if (this.state.selectedBundleId === "") {
			errors.selectedBundleId = 'Please select a bundle before continuing.';
		}
        if (this.state.selectedVehicle === "" && this.state.selectedBundleId !== "") {
			errors.selectedVehicle = 'Please select a vehicle type before continuing.';
		} 
        if (this.state.selectedRego === "" && this.state.selectedBundleId !== "") {
			errors.selectedVehicle = 'Please select a car registration before continuing.';
		}
        this.setState({
            errorField: errors
        })

		return errors;
	};

    async getBundles(){
        let res = await api("bundle/available", 'GET', null);
        if(res.status === 200){
            console.log(res.data);
            let bundles = res.data.bundles;
            this.setState({
                availableBundles: bundles,
                isLoading: false
            });
        } else {
            console.log(res);
        }
    }

    selectBundle(e){
        let bundleId = e.target.getAttribute('data-bundleid');
        this.setState({
            selectedBundleId: bundleId
        });
    }

    selectType(e){
        let type = e.target.value;
        this.setState({
            selectedVehicle: type

        });
    }
    
    setRego(e){
        let rego = e.target.value;
        this.setState({
            selectedRego: rego
        });
    }

    async purchaseBundle(){
        let errors = this.validate();
        console.log(errors);

        if(Object.keys(errors).length === 0) {
            this.setState({
                isLoading: true,
            });
    
            try {
                const resp = await api(`bundle/purchase/${this.state.selectedBundleId}?vehicleType=${this.state.selectedVehicle}&carRego=${this.state.selectedRego}`, "GET" , null);
                console.log(resp);
                if(resp.status == 200){
                    window.location.href = resp.data.stripeUrl
                }
                this.setState({
                    isLoading: false
                });
            } catch (error) {
                console.log(error);
                alert(error.response.data.error)
                this.setState({
                    isLoading: false
                });
            }
        }
    }

    componentDidMount(){
        document.title = 'Purchase A Bundle - Concierge Car Wash';
        const queryParams = new URLSearchParams(window.location.search);
        const hasUTM = queryParams.get('utm_campaign');
        let utmData = {};
        if(hasUTM){
            utmData.source = queryParams.get('utm_source');
            utmData.medium = queryParams.get('utm_medium');
            utmData.campaign = queryParams.get('utm_campaign');
            utmData.term = queryParams.get('utm_term');
            utmData.content = queryParams.get('utm_content');
            googleEvent(this.props.ReactGA, this.props.GTM, "access_via_utm", utmData);
        }
        this.getBundles();
        ReactPixel.pageView();
    }

    render(){
        let parentThis = this;
        return(
            <div className="bundlePage">
                {window.innerWidth < 767 && <HeaderMobile/>}
                <div className="prepaidBanner">
                </div>
                <div className="bookingHeader">
                    <div className="logoSection">    
                        <a href="https://conciergecarwash.com.au">
                            <img src={Images.ccwLogoLight} className="ccwLogo" alt="Logo" />
                        </a>                
                    </div>
                    <div className="titleSection">
                        <h1>Purchase a bundle</h1>
                    </div>
                    <div className="closeButtonSection">
                        {/* <a href="https://conciergecarwash.com.au">
                            <img className="closeIcon" src={Images.closeIcon} alt="close" />
                        </a> */}
                    </div>
                </div>
                {
                    parentThis.state.isLoading ? (
                        <Loading />
                    ) : (
                        <div className="bundleContainer displayCenter">
                            <div className="bundlesGrid">
                            {
                                parentThis.state.availableBundles.length == 0 ? (
                                    <div className="noBundles">
                                        <h3>Currently there are no bundles available</h3>
                                    </div>
                                ) : (
                                    parentThis.state.availableBundles.map(function(item, index){
                                        let isSingleItem = parentThis.state.availableBundles.length == 1 ? true : false;
                                        let isSelected = parentThis.state.selectedBundleId == item._id ? true : false;
                                        return (
                                            <div 
                                                key={index}
                                                className={`bundleItem ${isSingleItem && 'singleBundleItem'} ${isSelected && 'selectedItem'}`} 
                                                onClick={parentThis.selectBundle}
                                                data-bundleid={item._id}
                                            >
                                                {isSelected && <span className="bundleSelect">SELECTED</span>}
                                                <img src={item.image} alt="bundle image" className="bundleImage" />
                                                <h2 className="bundleTitle">{item.name}</h2>
                                                <p className="bundleDesc">{item.description}</p>
                                                <p className="bundleItemsHeading">{"Services Included: "}</p>
                                                <ul>
                                                    {
                                                        item.availableServices.map(function (service) {
                                                            return (
                                                                <li>{service.quantity}x {service.name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                )
                            }
                            </div>
                            <div className="purchaseButtonSection">
                                {
                                    this.state.selectedBundleId !== "" && (
                                        <div className="userInputs">
                                            <select onChange={this.selectType} value={this.state.selectedVehicle}>
                                                <option value="" selected={true} disabled={true}>Select vehicle type</option>
                                                <option value="sedan">Sedan</option>
                                                <option value="wagon">Wagon / SUV</option>
                                                <option value="4wd">4WD / 7 Seater</option>
                                            </select>
                                            <input 
                                                onKeyUp={this.setRego} 
                                                className="regoInput" 
                                                placeholder="Enter you car registration..."
                                            />
                                        </div>
                                    )
                                    
                                }
                                {
                                    Object.keys(this.state.errorField).length !== 0 && (
                                        <div>
                                            <p className="errorText">{this.state.errorField.selectedBundleId}</p>
                                            <p className="errorText">{this.state.errorField.selectedVehicle}</p>
                                        </div>
                                    )
                                }{
                                    parentThis.state.availableBundles.length !== 0 && (
                                        <button 
                                            className="purchaseButton"
                                            onClick={this.purchaseBundle}
                                        >
                                            Purchase
                                        </button>
                                    )
                                }
                                
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Bundle;