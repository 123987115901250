import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../../api";
import ExtraItem from "../ExtraItem";
import SubscriptionItem from "../SubscriptionItem";
import * as Images from "../../themes/images";
import NewServiceModal from "../newServiceModal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Extras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extraArray: [],
      subscriptionArray: [],
      giftCardDetails: {},
      giftCardActive: props.giftCardActive || false,
      locationExtras: [],
      extraServiceModalVisible: false,
      termsModalDetails: "",
      termsModalDisplay: false,
    };
    this.getAllExtra = this.getAllExtra.bind(this);
    this.selectExtra = this.selectExtra.bind(this);
    this.selectSubscription = this.selectSubscription.bind(this);
    this.handleGiftCardInputs = this.handleGiftCardInputs.bind(this);
    this.handleGiftCardActive = this.handleGiftCardActive.bind(this);
    this.validateGiftCardFields = this.validateGiftCardFields.bind(this);
    this.handleNewServiceModal = this.handleNewServiceModal.bind(this);
    this.termsModal = this.termsModal.bind(this);
  }

  async getAllExtra() {
    let res = await api(
      `extra?locationId=${this.props.selectLocation._id}`,
      "GET",
      null
    );
    if (res.status === 200) {
      if (res.data.success) {
        this.setState({
          extraArray: res.data.extras,
          subscriptionArray: res.data.subscriptions,
          locationExtras: res.data.locationExtras,
        });
        if (res.data.locationExtras.length > 0) {
          let showModal = true;
          for (
            let index = 0;
            index < this.props.selectedExtrasNames.length;
            index++
          ) {
            const element = this.props.selectedExtrasNames[index];
            if (element === res.data.locationExtras[0].name) {
              showModal = false;
            }
          }
          if (showModal) {
            this.setState({
              extraServiceModalVisible: true,
            });
          }
        }
      }
    } else {
      console.log("failed to get extras: ", res);
    }
  }

  selectExtra(extra) {
    let selectedExtras = this.props.selectedExtras;
    let selectedExtrasNames = this.props.selectedExtrasNames;

    if (selectedExtrasNames.includes(extra.name)) {
      const index = selectedExtrasNames.indexOf(extra.name);
      if (index > -1) {
        selectedExtrasNames.splice(index, 1);
        var secondIndex = selectedExtras.findIndex(function (o) {
          return o.name === extra.name;
        });
        if (secondIndex !== -1) selectedExtras.splice(secondIndex, 1);
      }
    } else {
      selectedExtrasNames.push(extra.name);
      selectedExtras.push(extra);
    }
    this.props.handleSelectedExtras(selectedExtras, selectedExtrasNames);
  }

  selectSubscription(sub) {
    let selectedSubscriptions = this.props.selectedSubscriptions;
    let selectedSubscriptionNames = this.props.selectedSubscriptionNames;
    let checkedSubs = this.props.subscriptionsChecked;

    if (selectedSubscriptionNames.includes(sub.name)) {
      const index = selectedSubscriptionNames.indexOf(sub.name);
      if (index > -1) {
        selectedSubscriptionNames.splice(index, 1);
        var secondIndex = selectedSubscriptions.findIndex(function (o) {
          return o.name === sub.name;
        });
        if (secondIndex !== -1) selectedSubscriptions.splice(secondIndex, 1);
      }
    } else {
      selectedSubscriptionNames.push(sub.name);
      selectedSubscriptions.push(sub);
    }
    this.props.handleSelectedSubscriptions(
      selectedSubscriptions,
      selectedSubscriptionNames
    );

    if (checkedSubs.includes(sub.name)) {
      const index = checkedSubs.indexOf(sub.name);
      if (index > -1) {
        checkedSubs.splice(index, 1);
      }
    }
    this.setState({
      subscriptionsChecked: checkedSubs,
    });
  }

  handleGiftCardActive() {
    const newActiveState = !this.state.giftCardActive;
    this.setState(
      { giftCardActive: newActiveState },
      () => {
        if (!newActiveState) {
          this.props.updateGiftCardValidity(true);
        } else {
          this.validateGiftCardFields();
        }
      }
    );
    this.props.handleGiftCardActive(newActiveState);
  }

  handleGiftCardInputs(event) {
    const { name, value } = event.target;
    this.setState(
      (prevState) => ({
        giftCardDetails: {
          ...prevState.giftCardDetails,
          [name]: value,
        },
      }),
      () => this.validateGiftCardFields()
    );

    setTimeout(() => {
      this.props.handleGiftCardDetails(this.state.giftCardDetails);
      if(this.state.giftCardActive){
        this.validateGiftCardFields();
      }
    }, 300);
  }

  validateGiftCardFields() {
    const { giftCardDetails } = this.state;
    const isValid =
      giftCardDetails?.firstName &&
      giftCardDetails?.lastName &&
      giftCardDetails?.email &&
      giftCardDetails?.mobile &&
      giftCardDetails?.amount > 0;

      console.log("giftCardDetails: ", giftCardDetails);

      console.log("isValid: ", isValid)

    this.props.updateGiftCardValidity(isValid);
  }

  handleNewServiceModal() {
    this.setState({
      extraServiceModalVisible: false,
    });
  }

  termsModal(details, show) {
    this.setState({
      termsModalDetails: details,
      termsModalDisplay: show,
    });
  }

  componentDidMount() {
    this.getAllExtra();
    this.setState({
      giftCardDetails: this.props.giftCardDetails,
      giftCardActive: this.props.giftCardActive,
    });
  }

  render() {
    return (
      <div className="extraStep">
        <h2>Select your extras</h2>
        {this.state.extraArray.length > 0 &&
          this.state.extraArray.map((extra, i) => (
            <ExtraItem
              key={i}
              extra={extra}
              selectExtra={() => this.selectExtra(extra)}
              selectedExtrasNames={this.props.selectedExtrasNames}
            />
          ))}
        <h2 className="notFirstHeading">Subscription</h2>
        {this.state.subscriptionArray.length > 0 &&
          this.state.subscriptionArray.map((sub, i) => (
            <SubscriptionItem
              key={i}
              sub={sub}
              subscriptionsChecked={this.props.subscriptionsChecked}
              checkSubscription={(name) => {this.props.checkSubscription(name); this.validateGiftCardFields()}}
              selectSubscription={() => this.selectSubscription(sub)}
              selectedSubscriptionNames={this.props.selectedSubscriptionNames}
              termsModal={(details, display) =>
                this.termsModal(details, display)
              }
            />
          ))}
        <h2 className="notFirstHeading">Gift Card</h2>
        <div className="giftCardSection">
          <img
            className={
              "giftCardCheckBox " + (this.state.giftCardActive ? "" : "unchecked")
            }
            src={this.state.giftCardActive ? Images.blueTick : Images.unChecked}
            alt="toggle"
            onClick={this.handleGiftCardActive}
          />
          <div>
            <h5 className="giftCardHeading" onClick={this.handleGiftCardActive}>
              Gift Card
            </h5>
            {this.state.giftCardActive && (
              <div className="giftCardForm">
                <div className="flexRow">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    onChange={this.handleGiftCardInputs}
                    value={this.state.giftCardDetails?.firstName || ""}
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    onChange={this.handleGiftCardInputs}
                    value={this.state.giftCardDetails?.lastName || ""}
                  />
                </div>
                <div className="flexRow">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email address"
                    onChange={this.handleGiftCardInputs}
                    value={this.state.giftCardDetails?.email || ""}
                  />
                </div>
                <div className="flexRow">
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Mobile"
                    onChange={this.handleGiftCardInputs}
                    value={this.state.giftCardDetails?.mobile || ""}
                  />
                </div>
                <div className="giftCardAmountSection">
                  <p className="giftCardAmount">Amount</p>
                  <div className="giftCardAmountInput">
                    <p className="amountDollar">$</p>
                    <input
                      type="number"
                      name="amount"
                      onChange={this.handleGiftCardInputs}
                      value={this.state.giftCardDetails?.amount || ""}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.extraServiceModalVisible && (
          <NewServiceModal
            service={this.state.locationExtras[0]}
            location={this.props.selectedLocationName}
            handleNewServiceModal={this.handleNewServiceModal}
            addNewService={() => {
              this.selectExtra(this.state.locationExtras[0]);
              this.handleNewServiceModal();
            }}
          />
        )}
        <Modal
          show={this.state.termsModalDisplay}
          onHide={() => {
            this.setState({ termsModalDisplay: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Subscription Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {this.state.termsModalDetails &&
                this.state.termsModalDetails.length > 0 &&
                this.state.termsModalDetails.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ termsModalDisplay: false });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Extras;