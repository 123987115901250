import React, { createRef } from "react";
import { CardOption, BookingPricingCard } from "../../components/";
import { api } from "../../api";
import Loading from "../Loading";
import moment from "moment-timezone";
import { toast, Bounce } from "react-toastify";
class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedService: "",
      serviceOptions: [
        {
          name: "Car Wash",
          isSelected: true,
        },
        {
          name: "Wash & Detail",
          isSelected: false,
        },
      ],
      locationPricing: [],
      loading: true,
      toastMessage: createRef(null),
    };
    this.selectServiceType = this.selectServiceType.bind(this);
    this.displayServiceCapacityModal =
      this.displayServiceCapacityModal.bind(this);
  }

  selectServiceType(id, name, price, time, currentHour) {
    const vehicleType = this.props.selectedVehicleType
      .split(" ")[0]
      .toLowerCase();
    const servicePrice = price;
    this.setState({
      selectedService: id,
    });

    this.props.handleSelectServiceType(name, id, servicePrice, currentHour);
  }

  async getLocationPricing(locationId) {
    const pricing = await api(`location/${locationId}/pricing`, "GET", null);
    if (pricing.status == 200) {
      var resp = pricing.data;
      if (resp.success) {
        // console.log("loc pricing", resp.pricing);
        this.setState({
          locationPricing: resp.pricing,
        });
      } else {
        alert(resp.message);
      }
    }
    this.setState({
      loading: false,
    });
  }

  componentDidMount() {
    if (typeof this.props.selectedServiceId == "object") {
      if (this.props.selectedServiceId.length > 0) {
        this.setState({
          selectedService: this.props.selectedServiceId[0].id,
        });
      }
    } else {
      if (this.props.selectedServiceId !== "") {
        this.setState({
          selectedService: this.props.selectedServiceId,
        });
      }
    }

    this.getLocationPricing(this.props.selectedLocation._id);
  }

  displayServiceCapacityModal() {
    const error = toast.error(
      "Sorry, you are unable to select this service due to the booking time you have selected. Please select another time or service, Thank you.",
      {
        toastId: "serviceSelectErrorToast",
        position: "bottom-center",
        style: {
          bottom: "5vh",
        },
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      }
    );
    this.state.toastMessage.current = error;
  }

  render() {
    let parentThis = this;
    const pricingOrder = [
      "Hand Polish",
      "Interior Detail",
      "Full Detail",
      "Paint Protection",
      "Express Wash",
      "Economy Wash",
      "Premium Wash",
      "Super Wash",
    ];
    const sortByObject = pricingOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    let pricingOptions = [];
    if (this.state.locationPricing) {
      pricingOptions = this.state.locationPricing.sort(
        (a, b) => sortByObject[a.name] - sortByObject[b.name]
      );
    }

    var allOptionsInactive = false;
    var countInactive = 0;
    var type = parentThis.props.selectedVehicleType;

    for (let po = 0; po < pricingOptions.length; po++) {
      const priceOption = pricingOptions[po];
      type = type.toLowerCase().split(" / ")[0].trim();
      if (priceOption[type]) {
        if (priceOption[type].active == false) {
          countInactive++;
        } else if (priceOption[type].value == 0) {
          countInactive++;
        }
      } else {
        countInactive++;
      }
    }

    if (countInactive == pricingOptions.length) {
      allOptionsInactive = true;
    }

    return (
      <div className="serviceStep">
        <h3>Select your service</h3>
        <div className="bookingPriceGrid">
          {this.state.loading ? (
            <div>
              <Loading />
            </div>
          ) : allOptionsInactive ? (
            <div>
              <p>
                Sorry, there are currently no services available for your
                selected vehicle type at{" "}
                {parentThis.props.selectedLocation.seoName}.
              </p>
              <p>
                Either select another location or contact us for more
                information.
              </p>
              <a
                className="contactLink"
                href={`${process.env.REACT_APP_MAIN_SITE_URL}/contact`}
                target={"_self"}
              >
                Contact Us
              </a>
            </div>
          ) : (
            pricingOptions.map(function (item, i) {
              var selectedType = parentThis.props.selectedVehicleType;
              return (
                <BookingPricingCard
                  key={i}
                  item={item}
                  selectedType={selectedType}
                  dateRange={parentThis.props.dateRange}
                  selectServiceType={(selectedItem, currentHour) => {
                    var canSelect = false;
                    if (
                      parentThis.props.serviceSpecificCapacities !==
                        undefined &&
                      parentThis.props.serviceSpecificCapacities !== false
                    ) {
                      let serviceId = selectedItem._id;
                      if (
                        parentThis.props.serviceSpecificCapacities[serviceId]
                      ) {
                        canSelect = false;
                        var selectedTime = moment(
                          parentThis.props.dateRange.split(" - ")[0],
                          "MMM Do h:mmA"
                        ).format("HH:mm");
                        var availability =
                          parentThis.props.serviceSpecificCapacities[serviceId];
                        for (
                          let index = 0;
                          index < availability.length;
                          index++
                        ) {
                          const element = availability[index];
                          if (element.timeStart == selectedTime) {
                            canSelect = true;
                            break;
                          }
                        }
                      } else {
                        canSelect = true;
                      }
                    } else {
                      canSelect = true;
                    }

                    if (canSelect) {
                      toast.dismiss(parentThis.state.toastMessage.current);
                      parentThis.selectServiceType(
                        selectedItem._id,
                        selectedItem.name,
                        selectedItem.pricing,
                        selectedItem.duration,
                        currentHour
                      );
                    } else {
                      parentThis.displayServiceCapacityModal();
                    }
                  }}
                  selectedService={parentThis.state.selectedService}
                  bundleSelected={parentThis.props.selectedBundle}
                  selectedLocation={parentThis.props.selectedLocation}
                />
              );
            })
          )}
        </div>
      </div>
    );
  }
}

export default Service;
