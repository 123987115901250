import React, { Component } from "react";

class ToggleSwitch extends Component {
  render() {
    return (
    <label className="switch">       
        <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name="locationTime"
            id="locationTime"
            onClick={(e) => {
                this.props.onClick(e);
            }}
            defaultChecked
        />
        <span className="slider round"></span>
      </label>
    );
  }
}

export default ToggleSwitch;