import React from "react";
import { greyClockIcon } from "../themes/images";
import moment from "moment-timezone";
class BookingPricingCard extends React.Component {
  render() {
    let type = this.props.selectedType;
    let item = this.props.item;
    let selectedLocation = this.props.selectedLocation;
    type = type.toLowerCase().split(" / ")[0].trim();
    let includeText = "";
    let isSelected = false;

    // console.log("price item: ", item);
    // console.log("type: ", type);
    // console.log("selectedService: ", this.props.selectedService);
    // console.log("dataRange: ", this.props.dateRange);
    // console.log("selectedLocation: ", selectedLocation);

    if (this.props.bundleSelected !== null) {
      if (this.props.selectedService) {
        if (typeof this.props.selectedService == "object") {
          if (
            this.props.selectedService[0].id == this.props.item.service[0]._id
          ) {
            isSelected = true;
          }
        } else {
          if (this.props.selectedService == this.props.item.service[0]._id) {
            isSelected = true;
          }
        }
      }
    } else {
      if (this.props.selectedService == this.props.item._id) {
        isSelected = true;
      }
    }

    //TUGERAH MOve Vacuum from premium to super
    if(String(selectedLocation._id) === "6327f728f6ec21e7e6b5b028" || String(selectedLocation._id) === "6327f728f6ec21e7e6b5b029"){
      let premiumWashItem = item.name === "Premium Wash";
      if (premiumWashItem) {
        item.includedOptions = item.includedOptions.filter(option => option !== "Vacuum");
      }
  
      // Add "Vacuum" to the includedOptions of the item with the name "Super WASH"
      let superWashItem = item.name === "Super Wash";
      if (superWashItem) {
          if (!item.includedOptions.includes("Vacuum")) {
            item.includedOptions.push("Vacuum");
          }
      }
    }

    // Get the timezone from the user's web browser
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Get the current time in the user's timezone
    const currentTime = moment().tz(userTimezone);

    // Get the current hour in the user's timezone
    const currentHour = moment(
      this.props.dateRange.split(" - ")[0],
      "MMM Do h:mma"
    ).hour();

    let salePrice = item.discount
      ? currentHour > item.discount.hour
        ? Number(item[type].value) - Number(item.discount.value)
        : false
      : false;

    switch (item["name"].toLowerCase().trim()) {
      case "express wash":
        includeText = "What's included";
        break;
      case "economy wash":
        includeText = "Express wash plus";
        break;
      case "premium wash":
        includeText = "Express wash plus +";
        break;
      case "super wash":
        includeText = "Premium wash plus +";
        break;
      case "hand polish":
        includeText = "What's included";
        break;
      case "interior detail":
        includeText = "What's included";
        break;
      case "full detail":
        includeText = "Hand Polish + Interior Detail +";
        if (selectedLocation.posId === 29) {
          includeText = "Interior Detail +";
        }
        break;
      case "paint protection":
        includeText = "Full Detail plus +";
        break;
      default:
        includeText = "";
        break;
    }

    let price = 0;
    let active = true;

    if (item[type]) {
      price = item[type].value;
      active = item[type].active;
    } else {
      active = false;
    }

    if (price == 0) {
      active = false;
    }

    if (!active) {
      return null;
    } else {
      return (
        <div
          className={
            "pricingCard" +
            (item["popular"] ? " mostPopular" : "") +
            (isSelected ? " selectedService" : "")
          }
        >
          <h2 className="pricingCardHeading">{item.name}</h2>
          {salePrice ? (
            <h2 className={"pricingCardText"}>
              <span className="originalPrice">From ${price}</span> ${salePrice}
            </h2>
          ) : (
            <h2 className="pricingCardPOA">From ${price}</h2>
          )}
          <div
            className={
              isSelected
                ? "bookNowButton"
                : "blueBorderButton bookingPriceSelect"
            }
            onClick={() => this.props.selectServiceType(item, currentHour)}
          >
            {isSelected ? "Selected" : "Choose"}
          </div>
          <div className="serviceDurationSection">
            <img className="durationIcon" src={greyClockIcon} alt="clock" />
            <p className="serviceDuration">
              {" From " + item.duration + "min"}
            </p>
          </div>
          {/* <p className="smallDesc">{this.props.item.service[0].description}</p> */}
          <p className="includedText lightBlueText">{includeText}</p>
          <ul className="includedItemsList">
            {item.includedOptions.map(function (listItem, i) {
              return (
                <li key={i} className="includedItem">
                  {listItem}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
}

export default BookingPricingCard;
