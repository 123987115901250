import React from "react";
import Box from '@mui/material/Box';
import * as Images from "../themes/images";
import SubscriptionLearnModal from "./subscriptionLearnModal";

// import * as Images from "../themes/images";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    maxWidth: "60%",
    maxHeight: "80%",
};


class SubscriptionItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        let isSelected = false;
        if(this.props.selectedSubscriptionNames.includes(this.props.sub.name)){
            isSelected = true;
        }
        let subChecked = false;
        if(this.props.subscriptionsChecked.includes(this.props.sub.name)){
            subChecked = true;
        }
        return(
            <div className="extraItemContainer">
                <div className={"extraItem " + (isSelected ? "activeItem" : "") } >
                    {
                        isSelected ? (
                            <img className="isActive checkIcon" src={Images.blueTick} alt="Checked" onClick={() => this.props.selectSubscription()} />
                        ) : (
                            <img src={Images.unChecked} className="uncheckedIcon" alt="Unchecked" onClick={() => this.props.selectSubscription()} />
                        )
                    }
                    <div className="extraContentWrapper">
                        <div className="extraName" onClick={() => this.props.selectSubscription()}>
                            <p className="extraOptionTitle">{this.props.sub.name}</p>
                        </div>
                        <div className="extraExamAndPrice">
                            <div className="extraExample">
                                <SubscriptionLearnModal 
                                    sub={this.props.sub} 
                                    selectSubscription={() => this.props.selectSubscription()}
                                    isSelected={isSelected}
                                />
                            </div>
                            <div onClick={() => this.props.selectSubscription()}>
                                <p className="extraPrice">${this.props.sub.price}/{this.props.sub.cadence.substring(0,1)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isSelected && (
                        <div className="subItemTerms" >
                            <p className="subItemTermsText">
                                <input 
                                    type="checkbox" 
                                    defaultChecked={subChecked} 
                                    onClick={() => { this.props.checkSubscription(this.props.sub.name) }}
                                /> 
                                {" "}
                                Agree to subscription <span className="termsLink" onClick={() => {
                                    this.props.sub.terms && 
                                    this.props.termsModal(
                                        this.props.sub.terms,
                                        true
                                    )
                                }}>terms and conditions</span>?
                            </p>
                        </div>
                    )
                }
            </div>
        )
        
    }
}

export default SubscriptionItem;