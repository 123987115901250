import React from "react";
import * as Images from "../themes/images";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import DatePicker from 'sassy-datepicker';
import moment from "moment";
import { TimeSelection } from "../components";
import { maxHeight } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 3.5,
    maxWidth: window.innerWidth > 820 ? 400 : "95%",
    maxHeight: window.innerHeight > 700 ? "80%" : "90%" ,
    borderRadius: "6px",
    outline: 0,
    zIndex: 11111
};

class NewServiceModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: true,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleAddNewService = this.handleAddNewService.bind(this);
    }

    handleClose(){
        this.props.handleNewServiceModal();
    }

    handleAddNewService(){
        this.props.addNewService();
    }

    render() {
        return (
            <Modal
                open={this.state.open}
                onClose={() =>  this.handleClose()}
                aria-labelledby="New Service Modal"
                aria-describedby="Location has added a new service"
                className="newServiceModal popupModal"
            >
                <Box sx={style}>
                    <div className="newServiceContent">
                        {
                            this.props.service.exampleImage && (
                                <div className="modalTop">
                                    <button onClick={() => this.handleClose()} className="modalCloseButton"> <img src={Images.closeIcon} className="modalClose" /></button>
                                    <img src={this.props.service.exampleImage} className="modalTopImage" />
                                </div>
                            )
                        }
                        <div className="modalBottom">
                            <div>
                                <h2 className="modalTitle">New Service!</h2>
                                <p className="modalDesc">{`${this.props.service.name} is now available at ${this.props.location}!`}</p>
                            </div>
                            <div>
                                <p className="noteText">{`Add to your booking for ${this.props.service.name} from $${this.props.service.price}`}</p>
                            </div>
                            <div className="buttonSection">
                                <button className="lightBlueButton" onClick={() => this.handleAddNewService()}> {`Add ${this.props.service.name}`} </button>
                            </div>
                        </div>
                       
                    </div>

                </Box>
            </Modal>
        )
    }
}

export default NewServiceModal;