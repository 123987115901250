import React, { Component } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar'
import 'bootstrap/dist/css/bootstrap.min.css';

class CustomProgressBar extends Component {
  render() {
    let value = this.props.progress;
    return (
      <ProgressBar now={value} />
    );
  }
}

export default CustomProgressBar;