import React from "react";
import { api, googleEvent } from "../../api";
import {
  Details,
  Service,
  Extras,
  Confirmation,
  Step,
  CustomProgressBar,
  BundleModal,
} from "../../components/";
import "./booking.css";
import * as Images from "../../themes/images";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Loading from "../../components/Loading";
import { withAuth0 } from "@auth0/auth0-react";
import { BookingTimeModal } from "../../components/";
import HeaderMobile from "../../components/headerMobile";
import DiscountBubble from "../../components/discountBubble";
import moment from "moment-timezone";
import DiscountProgressBar from "../../components/discountProgressBar";
import RewardModal from "../../components/rewardModal";
import GiveawayModal from "../../components/giveawayModal";
import ReactPixel from "react-facebook-pixel";
import giveawayCampaigns from "../../constants/giveawayCampaigns.json";
import { Helmet } from "react-helmet";

class Booking extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      isLoading: true,
      currentStep: 1,
      selectedLocation: "",
      steps: [
        {
          name: "Details",
          isSelected: true,
        },
        {
          name: "Service",
          isSelected: false,
        },
        {
          name: "Extras",
          isSelected: false,
        },
        {
          name: "Confirm Booking",
          isSelected: false,
        },
      ],
      selectedLocationName: "",
      selectedVehicleType: "",
      selectedServiceType: "",
      selectedBundleServices: "",
      selectedServiceId: "",
      selectedParentService: "",
      isUser: false,
      selectedExtras: [],
      selectedExtrasNames: [],
      selectedSubscriptions: [],
      selectedSubscriptionNames: [],
      subscriptionsChecked: [],
      selectedBookingTime: null,
      selectedTime: null,
      timeAmPm: "AM",
      progress: 25,
      showBundle: false,
      bundleDetails: {},
      selectedBundle: null,
      estimatedTotal: null,
      estimatedTime: null,
      discount: null,
      giftCardActive: false,
      giftCardDetails: null,
      bookingUserDetails: null,
      gEvent: null,
      openBookingModal: false,
      locationAvailability: null,
      availibilityInterval: 15,
      serviceSpecificCapacities: null,
      auth0Int: null,
      showSummary: false,
      rewardModalShow: false,
      hasDiscountCode: false,
      rewardModalTitle: null,
      rewardModalContent: null,
      showGiveaway: false,
      isGiftCardValid: true,
    };
    this.baseState = this.state;
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.handleSelectedVehicleType = this.handleSelectedVehicleType.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.selectedDate = this.selectedDate.bind(this);
    this.handleSelectedExtras = this.handleSelectedExtras.bind(this);
    this.handleSelectedTime = this.handleSelectedTime.bind(this);
    this.handleSelectServiceType = this.handleSelectServiceType.bind(this);
    this.createCartBooking = this.createCartBooking.bind(this);
    this.modifyCartBooking = this.modifyCartBooking.bind(this);
    this.handleForwardStep = this.handleForwardStep.bind(this);
    this.handleServiceStepForward = this.handleServiceStepForward.bind(this);
    this.handleBundleModal = this.handleBundleModal.bind(this);
    this.handleBundleDecline = this.handleBundleDecline.bind(this);
    this.handleBundleAccept = this.handleBundleAccept.bind(this);
    this.handleSelectedSubscriptions =
      this.handleSelectedSubscriptions.bind(this);
    this.handleGiftCardActive = this.handleGiftCardActive.bind(this);
    this.handleGiftCardDetails = this.handleGiftCardDetails.bind(this);
    this.updateBookingTime = this.updateBookingTime.bind(this);
    this.handleBookingUserDetails = this.handleBookingUserDetails.bind(this);
    this.confirmBooking = this.confirmBooking.bind(this);
    this.abandonedCart = this.abandonedCart.bind(this);
    this.resetAvailability = this.resetAvailability.bind(this);
    this.toggleDateModal = this.toggleDateModal.bind(this);
    this.calculateEstimate = this.calculateEstimate.bind(this);
    this.updateSelectedService = this.updateSelectedService.bind(this);
    this.goBack = this.goBack.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.preSelectService = this.preSelectService.bind(this);
    this.setReward = this.setReward.bind(this);
    this.setCoupon = this.setCoupon.bind(this);
    this.setOffer = this.setOffer.bind(this);
    this.handleRewardModal = this.handleRewardModal.bind(this);
    this.checkSubscription = this.checkSubscription.bind(this);
    this.updateGiftCardValidity = this.updateGiftCardValidity.bind(this);
  }

  async createCartBooking(activeCookies) {
    const { cookies } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    const hasUTM = queryParams.get("utm_source");
    const rewardExists = queryParams.get("reward");
    const couponExists = queryParams.get("coupon");
    const offerExists = queryParams.get("offer");
    const locationSelected = queryParams.get("location");

    let utmData = {};
    if (hasUTM) {
      utmData.source = queryParams.get("utm_source");
      utmData.medium = queryParams.get("utm_medium");
      utmData.campaign = queryParams.get("utm_campaign");
      utmData.term = queryParams.get("utm_term");
      utmData.content = queryParams.get("utm_content");
    } else {
      utmData = null;
    }

    let hasReward = false;
    let hasCoupon = false;
    let hasOffer = false;

    if (rewardExists) {
      hasReward = await this.getReward(rewardExists);
    }

    if (couponExists) {
      hasCoupon = await this.getCoupon(couponExists);
    }

    if (offerExists) {
      hasOffer = await this.getOffer(offerExists);
    }

    let data = {
      cookies: activeCookies.cookies,
      utmdata: utmData,
      hasReward: hasReward,
      hasCoupon: hasCoupon,
      hasOffer: hasOffer,
      locationSelected: locationSelected,
    };

    if (utmData) {
      if (giveawayCampaigns.campaigns.includes(utmData.campaign)) {
        this.setState({
          showGiveaway: true,
        });
      }
    }

    let res = await api("cart/create", "POST", data);
    if (res.status === 200) {
      if (res.data.success) {
        // console.log("create cart: ", res.data.response);
        cookies.remove("giftCardDetails", { path: "/", maxAge: 86400 });
        cookies.remove("subURL", { path: "/", maxAge: 86400 });
        cookies.remove("bookingNumber", { path: "/", maxAge: 86400 });
        cookies.remove("giftCardNumber", { path: "/", maxAge: 86400 });

        if (res.data.response.cart.sessionData) {
          for (const [key, value] of Object.entries(
            res.data.response.cart.sessionData
          )) {
            if (key !== "cartId") {
              let obj = {};
              if (key == "selectedServiceId") {
                if (value.length > 0) {
                  obj[key] = value[0].id;
                }
              }
              if (key == "selectedLocation") {
                if (locationSelected) {
                  // console.log("location selected: ", locationSelected);
                  this.selectLocation(locationSelected);
                } else {
                  obj[key] = value;
                  this.preSelectService(value);
                }
              } else {
                obj[key] = value;
              }
              this.setState(obj);
            }
          }

          if (res.data.response.cart.sessionData.selectedLocation) {
            let loc = res.data.response.cart.sessionData.selectedLocation;
            let data = {
              date: moment().format("YYYY-MM-DD"),
              selectedLocation: loc._id,
            };

            let availability = await api("location/availability", "POST", data);
            if (availability.status === 200) {
              if (availability.data.success) {
                let locationAvailability =
                  availability.data.locationAvailability;
                this.setState({
                  locationAvailability: locationAvailability,
                  availibilityInterval: availability.data.interval,
                  serviceSpecificCapacities:
                    availability.data.serviceSpecificCapacities,
                });
                // return res.data.locationAvailability;
              }
            } else {
              console.log("failed to get availability: ", availability);
            }
          }
        }
        this.setState({
          isLoading: false,
        });
        cookies.set("cartId", res.data.response.cart._id, {
          path: "/",
          maxAge: 86400,
        });
      }
    } else {
      console.log("failed to create cart: ", res);
    }

    this.setState({
      isLoading: false,
    });
  }

  async modifyCartBooking(state, value) {
    const { cookies } = this.props;
    let id = cookies.get("cartId");
    let data = {
      cartId: id,
      data: {
        state: state,
        value: value,
      },
    };
    let res = await api("cart/modify", "POST", data);
    if (res.status === 200) {
      if (!res.data.success) {
        if (res.data.response === "cart does not exist") {
          this.setState(this.baseState);
          this.createCartBooking(cookies);
        }
      } else {
        if (res.data.response.sessionData) {
          for (const [key, value] of Object.entries(
            res.data.response.sessionData
          )) {
            if (key !== "cartId") {
              let obj = {};
              if (key == "selectedServiceId") {
                if (value.length > 0) {
                  obj[key] = value[0].id;
                }
              }
              if (key == "selectedLocation") {
                const queryParams = new URLSearchParams(window.location.search);
                const locationSelected = queryParams.get("location");
                if (locationSelected) {
                  // console.log("selected loc:", locationSelected);
                } else {
                  obj[key] = value;
                  this.preSelectService(value);
                }
              } else {
                obj[key] = value;
              }
              this.setState(obj);
            }
          }
          return res.data.response.sessionData;
        }
      }
    } else {
      console.log("failed to modify cart: ", res);
    }
  }

  async confirmBooking() {
    const { cookies } = this.props;
    let id = cookies.get("cartId");
    let data = {
      cartId: id,
    };
    this.setState({
      isLoading: true,
    });
    try {
      let res = await api("booking/confirm", "POST", data);
      if (res.status == 200) {
        if (res.data.success) {
          if (res.data.sessionURL) {
            //this.setState(this.baseState);
            googleEvent(
              this.props.ReactGA,
              this.props.GTM,
              "user_confirm_booking_click",
              { sessionURL: res.data.sessionData }
            );
            cookies.set("subURL", res.data.subSessionURL, {
              path: "/",
              maxAge: 86400,
            });
            if (res.data.selectedSubs) {
              cookies.set("selectedSubs", res.data.selectedSubs.toString(), {
                path: "/",
                maxAge: 86400,
              });
            }
            window.location.href = res.data.sessionURL;
            return null;
          } else {
            console.log("failed to confirm booking: ", res.data);
          }
        } else {
          this.setState({
            isLoading: false,
          });
          alert(res.data.error);
        }
      } else {
        alert(res.data.error);
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      console.log("confirm booking error: ", error);
      this.setState({
        isLoading: false,
      });
      alert(error.response.data.error);
    }
  }

  async handleSelect(state, value) {
    let result = await this.modifyCartBooking(state, value);
    return result;
  }

  async handleSelectedLocation(value) {
    const queryParams = new URLSearchParams(window.location.search);
    const hasCoupon = queryParams.get("coupon");
    let removeCoupon = false;

    if (value.seoName !== this.state.selectedLocationName) {
      if (hasCoupon) {
        if (hasCoupon == "freewheelblingjoondalup1") {
          removeCoupon = true;
        }
      }
    }

    if (removeCoupon) {
      this.handleSelect(
        [
          "selectedLocation",
          "selectedLocationName",
          "selectedServiceId",
          "selectedServiceType",
          "selectedParentService",
          "hasCoupon",
          "couponCode",
        ],
        [
          value ? value : null,
          value !== "" ? value.seoName : null,
          null,
          null,
          null,
          false,
          false,
        ]
      );
    } else {
      this.handleSelect(
        [
          "selectedLocation",
          "selectedLocationName",
          "selectedServiceId",
          "selectedServiceType",
          "selectedParentService",
        ],
        [
          value ? value : null,
          value !== "" ? value.seoName : null,
          [],
          [],
          null,
        ]
      );
    }

    this.setState({
      selectedLocation: value,
      selectedLocationName: value.seoName,
      // selectedServiceId: "",
      // selectedServiceType: "",
      // selectedParentService: ""
    });
    googleEvent(this.props.ReactGA, this.props.GTM, "user_select_location", {
      location: value !== "" ? value.seoName : null,
    });
    if (this.state.selectedLocationName !== value) {
      this.setState({
        // selectedServiceType: "",
        // selectedServiceId: "",
        // selectedParentService: "",
        selectedBookingTime: null,
        selectedTime: null,
        timeAmPm: "AM",
      });
    }
    let data = {
      date: moment().format("YYYY-MM-DD"),
      selectedLocation: value._id,
    };

    let res = await api("location/availability", "POST", data);
    if (res.status === 200) {
      if (res.data.success) {
        let locationAvailability = res.data.locationAvailability;
        this.setState({
          locationAvailability: locationAvailability,
          availibilityInterval: res.data.interval,
          serviceSpecificCapacities: res.data.serviceSpecificCapacities,
        });
        // return res.data.locationAvailability;
      }
    } else {
      console.log("failed to get location availability: ", res);
    }
    this.preSelectService(value);
    // window.history.pushState(null, null, window.location.pathname);
    return value.seoName;
  }

  handleSelectedVehicleType(value) {
    this.handleSelect(["selectedVehicleType"], [value]);
    this.setState({
      selectedVehicleType: value,
    });
    googleEvent(
      this.props.ReactGA,
      this.props.GTM,
      "user_select_vehicle_type",
      { carType: value }
    );
  }

  handleUpdateUser(value) {
    googleEvent(this.props.ReactGA, this.props.GTM, "user_logged_in", {
      userType: value,
    });
    this.handleSelect(["isUser"], [value]);
    this.setState({
      isUser: value,
    });
  }

  handleSelectedTime(value, utcvalue, ampm, time) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    this.handleSelect(
      ["timezone", "selectedBookingTime", "timeAmPm", "selectedTime"],
      [timeZone, value, ampm, time]
    );
    this.setState({
      selectedBookingTime: value,
      timeAmPm: ampm,
      selectedTime: time,
    });
    googleEvent(
      this.props.ReactGA,
      this.props.GTM,
      "user_select_booking_time",
      { dateTime: value }
    );
  }

  handleSelectServiceType(value, id, servicePrice, currentHour) {
    let serviceObj = {
      id: id,
      quantity: 1,
      name: value,
      servicePrice: servicePrice,
      currentHour: currentHour,
    };

    googleEvent(this.props.ReactGA, this.props.GTM, "user_select_service", {
      serviceType: value,
      servicePrice: servicePrice,
    });

    this.handleSelect(
      [
        "selectedServiceType",
        "selectedServiceId",
        "bundleDetails",
        "selectedBundle",
        "selectedBundleServices",
      ],
      [[value], [serviceObj], null, null, null]
    );
    this.setState({
      selectedServiceType: [value],
      selectedServiceId: [serviceObj],
      bundleDetails: null,
      selectedBundle: null,
      selectedBundleServices: null,
    });
  }

  handleBundleModal() {
    this.setState({
      showBundle: false,
    });
  }

  handleRewardModal() {
    this.setState({
      rewardModalShow: false,
    });
  }

  handleShowSummaryMobile() {
    this.setState({
      showSummary: !this.state.showSummary,
    });
  }

  async handleBundleDecline() {
    const { cookies } = this.props;
    this.setState({
      showBundle: false,
    });
    this.handleServiceStepForward();
  }

  async handleBundleAccept(bundle) {
    const { cookies } = this.props;
    let selectedServices = "";
    let selectedServiceIds = [];
    let extras = this.state.selectedExtras;

    let estTotal = 0;
    if (extras.length > 0) {
      for (let ext = 0; ext < extras.length; ext++) {
        const element = extras[ext];
        estTotal = estTotal + element.price;
      }
    }

    estTotal = estTotal + bundle.bundle.salePrice;

    for (let bs = 0; bs < bundle.bundle.availableServices.length; bs++) {
      const element = bundle.bundle.availableServices[bs];
      selectedServices =
        selectedServices + `${element.name} x${element.quantity}, `;
      selectedServiceIds.push(element);
    }

    selectedServices = selectedServices.replace(/,\s*$/, "");
    selectedServices = selectedServices.trim();

    googleEvent(this.props.ReactGA, this.props.GTM, "user_select_bundle", {
      selectedBundle: bundle,
      selectedServices: selectedServices,
    });

    this.handleSelect(
      [
        "selectedBundle",
        "selectedServiceType",
        "selectedBundleServices",
        "selectedServiceId",
      ],
      [bundle, "", selectedServices, selectedServiceIds]
    );
    this.setState({
      selectedBundle: bundle,
      showBundle: false,
      selectedServiceType: "",
      selectedBundleServices: selectedServices,
      selectedServiceId: selectedServiceIds,
    });
    this.handleServiceStepForward();
  }

  handleServiceStepForward() {
    const { cookies } = this.props;
    this.setState((prevState) => ({
      steps: prevState.steps.map((obj) =>
        obj.name === "Service"
          ? Object.assign(obj, { isSelected: "completed" })
          : obj
      ),
    }));
    this.setState((prevState) => ({
      steps: prevState.steps.map((obj) =>
        obj.name === "Extras" ? Object.assign(obj, { isSelected: true }) : obj
      ),
    }));
    cookies.set("detailsStep", "completed", { path: "/", maxAge: 86400 });
    cookies.set("serviceStep", "completed", { path: "/", maxAge: 86400 });
    cookies.set("extrasStep", true, { path: "/", maxAge: 86400 });
    cookies.set("confirmStep", false, { path: "/", maxAge: 86400 });
    this.handleForwardStep();
  }

  handleSelectedExtras(value, names) {
    clearTimeout(this.state.gEvent);
    this.handleSelect(
      ["selectedExtras", "selectedExtrasNames"],
      [value, names]
    );
    this.setState({
      selectedExtras: value,
      selectedExtrasNames: names,
    });

    let gEvent = setTimeout(() => {
      let extras = "";
      for (let na = 0; na < names.length; na++) {
        const element = names[na];
        extras = extras == "" ? element : extras + ", " + element;
      }
      googleEvent(this.props.ReactGA, this.props.GTM, "user_select_extras", {
        extra: extras,
      });
    }, 2000);

    this.setState({
      gEvent: gEvent,
    });
  }

  handleSelectedSubscriptions(value, names) {
    clearTimeout(this.state.gEvent);
    this.handleSelect(
      ["selectedSubscriptions", "selectedSubscriptionNames"],
      [value, names]
    );
    this.setState({
      selectedSubscriptions: value,
      selectedSubscriptionNames: names,
    });

    let gEvent = setTimeout(() => {
      googleEvent(
        this.props.ReactGA,
        this.props.GTM,
        "user_select_subscriptions",
        {
          subscriptions: names,
        }
      );
    }, 2000);

    this.setState({
      gEvent: gEvent,
    });
  }

  checkSubscription(name) {
    let subscriptionsChecked = this.state.subscriptionsChecked;

    if (subscriptionsChecked.includes(name)) {
      const index = subscriptionsChecked.indexOf(name);
      if (index > -1) {
        subscriptionsChecked.splice(index, 1);
      }
    } else {
      subscriptionsChecked.push(name);
    }

    this.setState({
      subscriptionsChecked: subscriptionsChecked,
    });
  }

  handleGiftCardActive(value) {
    this.handleSelect(["giftCardActive"], [value]);
    this.setState({
      giftCardActive: value,
    });

    if (value == false) {
      this.handleSelect(["giftCardDetails", "giftCardActive"], [null, null]);
      this.setState({
        giftCardDetails: null,
      });
    }
  }

  handleGiftCardDetails(value) {
    clearTimeout(this.state.gEvent);

    this.handleSelect(["giftCardDetails"], [value]);
    this.setState({
      giftCardDetails: value,
    });
  }

  // Update gift card validity based on extras component
  updateGiftCardValidity(isValid) {
    console.log("isValid: ", isValid);
    this.setState({ isGiftCardValid: isValid });
  }

  async handleBookingUserDetails(value) {
    let result = await this.handleSelect(["bookingUserDetails"], [value]);
    this.setState({
      bookingUserDetails: value,
    });
    return result.bookingUserDetails;
  }
  handleForwardStep() {


    this.handleSelect(
      ["currentStep", "progress"],
      [Number(this.state.currentStep) + 1, Number(this.state.progress) + 25]
    );
    this.setState({
      currentStep: Number(this.state.currentStep) + 1,
      progress: Number(this.state.progress) + 25,
    });
    let stepName = "";
    switch (Number(this.state.currentStep) + 1) {
      case 1:
        stepName = "Step - Booking Details";
        break;
      case 2:
        stepName = "Step - Service";
        break;
      case 3:
        stepName = "Step - Extras";
        break;
      case 4:
        stepName = "Step - Confirm Booking";
        break;
      default:
        stepName = "Step - Booking Details";
        break;
    }
    googleEvent(
      this.props.ReactGA,
      this.props.GTM,
      "user_booking_step_forward",
      {
        currentStep: Number(this.state.currentStep) + 1,
        progress: Number(this.state.progress) + 25,
        stepName: stepName,
      }
    );
  }

  async goForward() {
    window.stop();
    const { cookies } = this.props;
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    switch (this.state.currentStep) {
      case 1:
        if (this.state.selectedBookingTime == null) {
          alert("Booking Date and Time must be selected before continuing");
          return;
        }
        this.setState((prevState) => ({
          steps: prevState.steps.map((obj) =>
            obj.name === "Details"
              ? Object.assign(obj, { isSelected: "completed" })
              : obj
          ),
        }));
        this.setState((prevState) => ({
          steps: prevState.steps.map((obj) =>
            obj.name === "Service"
              ? Object.assign(obj, { isSelected: true })
              : obj
          ),
        }));
        cookies.set("detailsStep", "completed", { path: "/", maxAge: 86400 });
        cookies.set("serviceStep", true, { path: "/", maxAge: 86400 });
        cookies.set("extrasStep", false, { path: "/", maxAge: 86400 });
        cookies.set("confirmStep", false, { path: "/", maxAge: 86400 });
        this.handleForwardStep();
        break;
      case 2:
        let data = new FormData();

        data.append("cartId", cookies.get("cartId"));
        let res = await api("bundle/checkCart", "POST", data);
        if (res.status === 200) {
          if (res.data.success) {
            if (res.data.hasBundle) {
              if (this.state.selectedBundle !== null) {
                this.handleServiceStepForward();
              } else {
                this.setState({
                  showBundle: true,
                  bundleDetails: res.data.bundleDetails,
                });
              }
            } else {
              this.handleServiceStepForward();
            }
          } else {
            this.handleServiceStepForward();
          }
        } else {
          this.handleServiceStepForward();
        }
        break;
      case 3:
        this.setState((prevState) => ({
          steps: prevState.steps.map((obj) =>
            obj.name === "Extras"
              ? Object.assign(obj, { isSelected: "completed" })
              : obj
          ),
        }));
        this.setState((prevState) => ({
          steps: prevState.steps.map((obj) =>
            obj.name === "Confirm Booking"
              ? Object.assign(obj, { isSelected: true })
              : obj
          ),
        }));
        cookies.set("detailsStep", "completed", { path: "/", maxAge: 86400 });
        cookies.set("serviceStep", "completed", { path: "/", maxAge: 86400 });
        cookies.set("extrasStep", "completed", { path: "/", maxAge: 86400 });
        cookies.set("confirmStep", true, { path: "/", maxAge: 86400 });
        this.handleForwardStep();
        break;
    }
  }

  goBack(props = null) {
    const { cookies } = this.props;
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    if (this.state.currentStep !== 1) {
      this.handleSelect(
        ["currentStep", "progress"],
        [Number(this.state.currentStep) - 1, Number(this.state.progress) - 25]
      );
      this.setState({
        currentStep: Number(this.state.currentStep) - 1,
        progress: Number(this.state.progress) - 25,
      });
      let stepName = "";
      switch (Number(this.state.currentStep) - 1) {
        case 1:
          stepName = "Step - Booking Details";
          break;
        case 2:
          stepName = "Step - Service";
          break;
        case 3:
          stepName = "Step - Extras";
          break;
        case 4:
          stepName = "Step - Confirm Booking";
          break;
        default:
          stepName = "Step - Booking Details";
          break;
      }
      googleEvent(
        this.props.ReactGA,
        this.props.GTM,
        "user_booking_step_backward",
        {
          currentStep: Number(this.state.currentStep) - 1,
          progress: Number(this.state.progress) - 25,
          stepName: stepName,
        }
      );
      switch (this.state.currentStep) {
        case 2:
          this.setState((prevState) => ({
            steps: prevState.steps.map((obj) =>
              obj.name === "Details"
                ? Object.assign(obj, { isSelected: true })
                : obj
            ),
          }));
          this.setState((prevState) => ({
            steps: prevState.steps.map((obj) =>
              obj.name === "Service"
                ? Object.assign(obj, { isSelected: false })
                : obj
            ),
          }));
          cookies.set("detailsStep", true, { path: "/", maxAge: 86400 });
          cookies.set("serviceStep", false, { path: "/", maxAge: 86400 });
          cookies.set("extrasStep", false, { path: "/", maxAge: 86400 });
          cookies.set("confirmStep", false, { path: "/", maxAge: 86400 });
          break;
        case 3:
          this.setState((prevState) => ({
            steps: prevState.steps.map((obj) =>
              obj.name === "Service"
                ? Object.assign(obj, { isSelected: true })
                : obj
            ),
          }));
          this.setState((prevState) => ({
            steps: prevState.steps.map((obj) =>
              obj.name === "Extras"
                ? Object.assign(obj, { isSelected: false })
                : obj
            ),
          }));
          cookies.set("detailsStep", "completed", { path: "/", maxAge: 86400 });
          cookies.set("serviceStep", true, { path: "/", maxAge: 86400 });
          cookies.set("extrasStep", false, { path: "/", maxAge: 86400 });
          cookies.set("confirmStep", false, { path: "/", maxAge: 86400 });
          break;
        case 4:
          this.setState((prevState) => ({
            steps: prevState.steps.map((obj) =>
              obj.name === "Extras"
                ? Object.assign(obj, { isSelected: true })
                : obj
            ),
          }));
          this.setState((prevState) => ({
            steps: prevState.steps.map((obj) =>
              obj.name === "Confirm Booking"
                ? Object.assign(obj, { isSelected: false })
                : obj
            ),
          }));
          cookies.set("detailsStep", "completed", { path: "/", maxAge: 86400 });
          cookies.set("serviceStep", "completed", { path: "/", maxAge: 86400 });
          cookies.set("extrasStep", true, { path: "/", maxAge: 86400 });
          cookies.set("confirmStep", false, { path: "/", maxAge: 86400 });
          break;
      }
    }
  }

  goBackHistory() {
    // window.location.href = "https://conciergecarwash.com.au";
    window.history.back();
  }

  async updateBookingTime() {
    const { cookies } = this.props;
    this.setState({
      openBookingModal: true,
    });
  }

  toggleDateModal(val) {
    if (!this.state.selectedLocation && !this.state.selectedVehicleType) {
      alert(
        "You must select a location and vehicle type before you can select a time"
      );
      return;
    } else if (!this.state.selectedLocation) {
      alert("You must select a location before you can select a time");
      return;
    } else if (!this.state.selectedVehicleType) {
      alert("You must select a vehicle type before you can select a time");
      return;
    }

    this.setState({
      openBookingModal: val,
    });
  }

  async selectedDate(value) {
    if (!this.state.selectedLocation && !this.state.selectedVehicleType) {
      alert(
        "You must select a location and vehicle type before you can select a time"
      );
    } else if (!this.state.selectedLocation) {
      alert("You must select a location before you can select a time");
    } else if (!this.state.selectedVehicleType) {
      alert("You must select a vehicle type before you can select a time");
    }
    let data = {
      date: value,
      selectedLocation: this.state.selectedLocation._id,
    };

    let res = await api("location/availability", "POST", data);
    if (res.status === 200) {
      if (res.data.success) {
        let locationAvailability = res.data.locationAvailability;
        this.setState({
          locationAvailability: locationAvailability,
          availibilityInterval: res.data.interval,
          serviceSpecificCapacities: res.data.serviceSpecificCapacities,
        });
        // return res.data.locationAvailability;
      }
    } else {
      console.log(
        "failed to get location availability after selecting date: ",
        res
      );
    }
  }

  resetAvailability() {
    this.setState({
      locationAvailability: null,
    });
  }

  subscriptionTotal(subs) {
    let total = 0;
    for (let s = 0; s < subs.length; s++) {
      const element = subs[s];
      let value = 0;
      switch (element.cadence) {
        case "monthly":
          value = element.price;
          break;
        case "yearly":
          value = element.price / 12;
          break;
        case "fortnightly":
          value = element.price * 2;
          break;
        case "weekly":
          value = element.price * 4;
          break;
        default:
          value = element.price;
          break;
      }

      total = total + value;
    }

    return `$${total}`;
  }

  async abandonedCart() {
    const { cookies } = this.props;
    let id = cookies.get("cartId");
    let data = {
      cartId: id,
    };
    let res = await api("cart/abandoned", "POST", data);
    if (res.status === 200) {
      console.log("res.data.cart:", res.data)
      googleEvent(this.props.ReactGA, this.props.GTM, "user_abandoned_cart", {
        cartId: id,
        user: res.data.response.cart.sessionData.bookingUserDetails.email,
      });
    } else {
      console.log("failed to abandon cart: ", res);
    }
  }

  async getUserToken(authUser) {
    var tok = await authUser.getAccessTokenSilently();
    if (authUser.isAuthenticated) {
      if (authUser.user) {
        let data = {
          auth0Id: authUser.user.sub,
        };
        if (authUser) {
          let res = await api("customer/token", "PUT", data);
          if (res.status === 200) {
            let data = res.data.data;
            let bookingUserDetails = {
              firstName: data.given_name
                ? data.given_name
                : data.user_metadata
                ? data.user_metadata.firstName
                : "",
              lastName: data.family_name
                ? data.family_name
                : data.user_metadata
                ? data.user_metadata.lastName
                : "",
              email: data.email ? data.email : "",
              phone: data.user_metadata ? data.user_metadata.mobile : "",
              picture: data?.picture,
            };

            this.handleSelect(
              ["isUser", "bookingUserDetails"],
              [data.user_id, bookingUserDetails]
            );
            if (this.state.isUser == false) {
              googleEvent(
                this.props.ReactGA,
                this.props.GTM,
                "user_logged_in",
                { userType: data.user_id }
              );
            }
            this.setState({
              isUser: data.user_id,
              bookingUserDetails: bookingUserDetails,
            });
            setTimeout(() => {
              this.setState({
                isLoading: false,
              });
            }, 500);
          } else {
            console.log("failed to get auth0 user: ", res);
          }
        }
      } else {
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 500);
      }
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 500);
    }
  }

  updateSelectedService() {
    let selectedLocationPricing = this.state.selectedLocation.pricing;
    if (this.state.selectedBundle == null) {
      for (let pr = 0; pr < selectedLocationPricing.length; pr++) {
        const element = selectedLocationPricing[pr];
        let selectedId =
          typeof this.state.selectedServiceId == "string"
            ? this.state.selectedServiceId
            : this.state.selectedServiceId[0].id;
        if (element._id == selectedId) {
          let time = moment(this.state.selectedTime.split(" ")[0], "hA").format(
            "H"
          );
          if (
            time >= element.timeOfDay[0].startTimeHour &&
            time <= element.timeOfDay[0].endTimeHour
          ) {
            //time matches keep current service
          } else {
            //time does not match, update service
            let currentServiceId = element._id;
            let currentServiceName = element.service[0].name;
            for (let us = 0; us < selectedLocationPricing.length; us++) {
              const el = selectedLocationPricing[us];
              if (currentServiceName == el.service[0].name) {
                if (currentServiceId !== el._id) {
                  let type = this.state.selectedVehicleType.toLocaleLowerCase();
                  let price = el.pricing.discount
                    ? el.pricing[type] - el.pricing.discount
                    : el.pricing[type];

                  const userTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                  // Get the current time in the user's timezone
                  const currentTime = moment().tz(userTimezone);

                  // Get the current hour in the user's timezone
                  const currentHour = currentTime.hour();
                  this.handleSelectServiceType(
                    el.service[0].name,
                    this.state.selectedParentService,
                    el._id,
                    price,
                    currentHour
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  async calculateEstimate() {
    const { cookies } = this.props;
    let id = cookies.get("cartId");
    let data = {
      cartId: id,
    };
    let res = await api("cart/calculateEstimate", "POST", data);
    if (res.status === 200) {
      this.setState({
        estimatedTime: res.data.response.estTime,
        estimatedTotal: res.data.response.estAmount,
      });
      this.handleSelect(
        ["estimatedTime", "estimatedTotal"],
        [res.data.response.estTime, res.data.response.estAmount]
      );
    } else {
      console.log("failed to calculate estimate: ", res);
    }
  }

  async selectLocation(locationid) {
    let res = await api(`location/pricing/${locationid}`, "GET", null);
    if (res.status === 200) {
      this.handleSelectedLocation(res.data.location);
    } else {
      console.log("failed to select location: ", res);
    }
  }

  async preSelectService(location) {
    const queryParams = new URLSearchParams(window.location.search);
    const serviceSelected = queryParams.get("service");
    if (serviceSelected) {
      if (this.state.selectedServiceType == "") {
        for (let lp = 0; lp < location.pricing.length; lp++) {
          const element = location.pricing[lp];
          if (element.service[0].name == serviceSelected) {
            let parent = "Car Wash";
            switch (serviceSelected) {
              case "Hand Polish":
                parent = "Wash & Detail";
                break;
              case "Full Detail":
                parent = "Wash & Detail";
                break;
              case "Interior Detail":
                parent = "Wash & Detail";
                break;
              case "Paint Protection":
                parent = "Wash & Detail";
                break;
            }
            const userTimezone =
              Intl.DateTimeFormat().resolvedOptions().timeZone;
            // Get the current time in the user's timezone
            const currentTime = moment().tz(userTimezone);

            // Get the current hour in the user's timezone
            const currentHour = currentTime.hour();

            this.handleSelectServiceType(
              element.service[0].name,
              parent,
              element._id,
              "",
              currentHour
            );
          }
        }
        // this.setState({
        //     selectedServiceType: [serviceSelected]
        // })
      }
    }
  }

  async getReward(code) {
    const getReward = await api(`reward/${code}`, "GET", null);
    if (getReward.status == 200) {
      if (getReward.data.reward !== null && getReward.data.reward.active) {
        this.setState({
          rewardModalShow: true,
          hasDiscountCode: true,
        });
        return getReward.data.reward.comment;
      }
    }

    return false;
  }

  async setReward(code) {
    let reward = await this.getReward(code);
    setTimeout(() => {
      this.handleSelect(
        ["hasReward", "rewardCode"],
        [reward, reward ? code : false]
      );
    }, 750);
  }

  async getCoupon(code) {
    const getCouponStatus = await api(`coupon/${code}`, "GET", null);
    if (getCouponStatus.status == 200) {
      if (
        getCouponStatus.data.coupon !== null &&
        getCouponStatus.data.coupon.active
      ) {
        this.setState({
          rewardModalShow: true,
          hasDiscountCode: true,
        });
        return getCouponStatus.data.coupon.comment;
      }
    }

    return false;
  }

  async setCoupon(code) {
    let coupon = await this.getCoupon(code);
    setTimeout(() => {
      this.handleSelect(
        ["hasCoupon", "couponCode"],
        [coupon, coupon ? code : false]
      );
    }, 750);
  }

  async getOffer(code) {
    const getOffer = await api(`offer/?code=${code}`, "GET", null);
    if (getOffer.status == 200) {
      if (getOffer.data.offer !== null) {
        let serviceNames = [];
        for (let os = 0; os < getOffer.data.offer.services.length; os++) {
          const element = getOffer.data.offer.services[os];
          serviceNames.push(element.name);
        }
        serviceNames = serviceNames.toString();
        serviceNames = serviceNames.replace(/,/g, ", ");
        var offerType =
          getOffer.data.offer.type == "nodiscount"
            ? "special offer"
            : "discount";
        var serviceText =
          getOffer.data.offer.services.length > 1
            ? "either " + serviceNames
            : serviceNames;
        this.setState({
          rewardModalTitle: "Your special offer is now active! ",
          rewardModalContent:
            getOffer.data.offer.service == null
              ? getOffer.data.offer.location !== null
                ? `Make sure to select the ${getOffer.data.offer.location.seoName} location to apply the discount at checkout`
                : "Make sure to select  " +
                  serviceText +
                  " to apply the discount at checkout."
              : `Make sure to select the ${getOffer.data.offer.service.name} service to apply the ${offerType} at checkout.`,
          rewardModalShow: true,
          hasDiscountCode: true,
        });
        return getOffer.data.offer.comment;
      }
    }
    return false;
  }

  async setOffer(code) {
    let offer = await this.getOffer(code);
    setTimeout(() => {
      this.handleSelect(
        ["hasOffer", "offerCode"],
        [offer, offer ? code : false]
      );
    }, 750);
  }

  componentDidMount() {
    const { cookies } = this.props;
    this.createCartBooking(cookies);
    ReactPixel.pageView();
    const queryParams = new URLSearchParams(window.location.search);
    const bookingCanceled = queryParams.get("canceled");
    const rewardExists = queryParams.get("reward");
    const couponExists = queryParams.get("coupon");
    const offerExists = queryParams.get("offer");
    if (bookingCanceled) {
      this.abandonedCart();
    }

    if (rewardExists) {
      this.setReward(rewardExists);
    } else {
      setTimeout(() => {
        this.handleSelect(["hasReward", "rewardCode"], [false, false]);
      }, 750);
    }

    if (couponExists) {
      this.setCoupon(couponExists);
    } else {
      setTimeout(() => {
        this.handleSelect(["hasCoupon", "couponCode"], [false, false]);
      }, 750);
    }

    if (offerExists) {
      this.setOffer(offerExists);
    } else {
      setTimeout(() => {
        this.handleSelect(["hasOffer", "offerCode"], [false, false]);
      }, 750);
    }

    const pageThis = this;

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      if (pageThis.state.currentStep == 1) {
        window.history.back();
      } else {
        pageThis.goBack(pageThis.props);
      }
    };

    //this.props.auth0.logout();
    this.props.auth0.getAccessTokenSilently();

    let auth0 = setInterval(() => {
      if (this.props.auth0.isLoading == false) {
        clearInterval(this.state.auth0Int);
        if (this.props.auth0.isAuthenticated) {
          this.getUserToken(this.props.auth0);
        } else {
          // this.setState({
          //     isLoading: false
          // })
        }
      }
    }, 200);

    this.setState({
      auth0Int: auth0,
    });
  }

  render() {
    const servicePrice =
      this.state.selectedServiceId.length > 0
        ? this.state.selectedServiceId[0]["servicePrice"]
        : "";
    var disabledContinue = false;
    const meta = {
      title: "Book Online | Hand Car Wash & Detailing | Concierge Car Wash",
      description:
        "With over 30 locations around Australia, find a Concierge Car Wash near you and book your car wash or detailing service today. Proudly 100% Hand-Only Car Wash.",
    };

    if (this.state.currentStep == 3) {
      if (
        (this.state.selectedSubscriptionNames.length !==
        this.state.subscriptionsChecked.length) || 
        this.state.giftCardActive && !this.state.isGiftCardValid
      ) {
        disabledContinue = true;
      }
    }

    return (
      <div className="bookingPage">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        {window.innerWidth <= 767 && <HeaderMobile />}
        <div className="bookingBanner">
          <h3>
            <span className="lightBlueText">Drive-Ins</span> always available at
            any location
          </h3>
        </div>
        {this.state.hasDiscountCode
          ? null
          : window.innerWidth <= 767 &&
            this.state.currentStep !== 4 && (
              <DiscountBubble
                estimatedTotal={this.state.estimatedTotal}
                cartThreshold={100}
              />
            )}
        <div className="bookingHeader">
          <div className="logoSection">
            <a href="https://conciergecarwash.com.au">
              <img src={Images.ccwLogoLight} className="ccwLogo" alt="Logo" />
            </a>
          </div>
          <div className="titleSection">
            <h1>Make A Booking</h1>
            <div className="stepSection">
              {!this.state.isLoading && (
                <Step cookie={this.props} items={this.state.steps} />
              )}
            </div>
          </div>
          <div className="closeButtonSection">
            <a href="https://conciergecarwash.com.au">
              <img className="closeIcon" src={Images.closeIcon} alt="close" />
            </a>
          </div>
        </div>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div className={"bookingFlowSection "}>
            <div
              className={
                this.state.currentStep < 4
                  ? "stepSections "
                  : "stepSections confirmStep"
              }
            >
              {this.state.currentStep === 1 ? (
                <Details
                  handleSelectedLocation={this.handleSelectedLocation}
                  handleSelectedVehicleType={this.handleSelectedVehicleType}
                  isUser={this.state.isUser}
                  handleUpdateUser={this.handleUpdateUser}
                  selectedLocationName={this.state.selectedLocationName}
                  selectedVehicleType={this.state.selectedVehicleType}
                  selectedBookingTime={this.state.selectedBookingTime}
                  selectedTime={this.state.selectedTime}
                  openDateModal={this.toggleDateModal}
                  openBookingModal={this.state.openBookingModal}
                  bookingUserDetails={this.state.bookingUserDetails}
                />
              ) : this.state.currentStep === 2 ? (
                <Service
                  serviceSpecificCapacities={
                    this.state.serviceSpecificCapacities
                  }
                  selectedLocation={this.state.selectedLocation}
                  selectedVehicleType={this.state.selectedVehicleType}
                  selectedServiceId={this.state.selectedServiceId}
                  selectedParentService={this.state.selectedParentService}
                  timeAmPm={this.state.timeAmPm}
                  dateRange={this.state.selectedBookingTime}
                  handleSelectServiceType={this.handleSelectServiceType}
                  selectedBundle={this.state.selectedBundle}
                  discount={this.state.discount}
                />
              ) : this.state.currentStep === 3 ? (
                <Extras
                  handleSelectedExtras={this.handleSelectedExtras}
                  handleSelectedSubscriptions={this.handleSelectedSubscriptions}
                  handleGiftCardDetails={this.handleGiftCardDetails}
                  handleGiftCardActive={this.handleGiftCardActive}
                  selectLocation={this.state.selectedLocation}
                  selectedLocationName={this.state.selectedLocationName}
                  selectedExtras={this.state.selectedExtras}
                  selectedExtrasNames={this.state.selectedExtrasNames}
                  selectedSubscriptions={this.state.selectedSubscriptions}
                  selectedSubscriptionNames={
                    this.state.selectedSubscriptionNames
                  }
                  checkSubscription={this.checkSubscription}
                  subscriptionsChecked={this.state.subscriptionsChecked}
                  giftCardDetails={this.state.giftCardDetails}
                  giftCardActive={this.state.giftCardActive}
                  isGiftCardValid={this.state.isGiftCardValid}
                  updateGiftCardValidity={this.updateGiftCardValidity}
                  googleEvent={googleEvent}
                  ReactGA={this.props.ReactGA}
                  GTM={this.props.GTM}
                />
              ) : (
                <Confirmation
                  updateBookingTime={this.updateBookingTime}
                  selectedBookingTime={this.state.selectedBookingTime}
                  handleBookingUserDetails={this.handleBookingUserDetails}
                  bookingUserDetails={this.state.bookingUserDetails}
                  confirmBooking={this.confirmBooking}
                  goBack={() => this.goBack(this.props)}
                  googleEvent={googleEvent}
                  isUser={this.state.isUser}
                  ReactGA={this.props.ReactGA}
                  GTM={this.props.GTM}
                  selectedLocationName={this.state.selectedLocationName}
                  estimatedTotal={this.state.estimatedTotal}
                  selectedVehicleType={this.state.selectedVehicleType}
                  selectedBundle={this.state.selectedBundle}
                  selectedBundleServices={this.state.selectedBundleServices}
                  selectedServiceType={this.state.selectedServiceType}
                  selectedExtrasNames={this.state.selectedExtrasNames}
                  selectedSubscriptions={this.state.selectedSubscriptions}
                  selectedSubscriptionNames={
                    this.state.selectedSubscriptionNames
                  }
                  giftCardActive={this.state.giftCardActive}
                  giftCardDetails={this.state.giftCardDetails}
                  estimatedTime={this.state.estimatedTime}
                  discount={this.state.discount}
                  progress={this.state.progress}
                  subscriptionTotal={this.subscriptionTotal}
                />
              )}
            </div>
            {window.innerWidth > 820 && (
              <div className="bookingProgressSection">
                <div className="summaryBox">
                  <h2>Quote summary</h2>
                  {this.state.hasDiscountCode ? null : this.state
                      .estimatedTotal >= 100 ? (
                    <p className="added-coupon">
                      $10 Coupon Added!{" "}
                      <span className="green-tick">
                        <img src={Images.greenTick} alt="" />
                      </span>
                    </p>
                  ) : (
                    <p>Order over $100 to recieve $10 off </p>
                  )}
                  {this.state.hasDiscountCode ? null : (
                    <div className="progressBarSection">
                      <DiscountProgressBar
                        type={"desktop"}
                        amount={`${(this.state.estimatedTotal / 100) * 100}%`}
                      />
                    </div>
                  )}
                  <div className="yourDetailSection">
                    <h3>Your Details</h3>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Location</p>
                      <p className="lightBlueText">
                        {this.state.selectedLocationName ? (
                          this.state.selectedLocationName
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Vehicle type</p>
                      <p className="lightBlueText">
                        {this.state.selectedVehicleType ? (
                          this.state.selectedVehicleType
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    <h3 className="servicesSectionHeading">Your Services</h3>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Time of arrival</p>
                      <p className="lightBlueText">
                        {this.state.selectedBookingTime ? (
                          this.state.selectedBookingTime
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Type</p>
                      <p className="lightBlueText">
                        {this.state.selectedBundle ? (
                          this.state.selectedBundleServices
                        ) : this.state.selectedServiceType ? (
                          this.state.selectedServiceType
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Extras</p>
                      <p className="lightBlueText">
                        {this.state.selectedExtrasNames.length > 0 ? (
                          this.state.selectedExtrasNames.join(", ")
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Subscriptions</p>
                      {this.state.selectedSubscriptions !== null &&
                      this.state.selectedSubscriptions.length > 0 ? (
                        <p className="lightBlueText">
                          {" "}
                          {this.state.selectedSubscriptionNames.join(", ")}
                        </p>
                      ) : (
                        <span className="noValue">-</span>
                      )}
                    </div>
                    {this.state.giftCardActive &&
                      this.state.giftCardDetails && (
                        <div>
                          <h3 className="servicesSectionHeading">
                            Gift Card Purchase Detail
                          </h3>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Name on card</p>
                            <p className="lightBlueText">{`${
                              this.state.giftCardDetails.firstName
                            } ${
                              this.state.giftCardDetails.lastName
                                ? this.state.giftCardDetails.lastName
                                : ""
                            }`}</p>
                          </div>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Email</p>
                            <p className="lightBlueText largerTextWidth">
                              {this.state.giftCardDetails.email ? (
                                this.state.giftCardDetails.email
                              ) : (
                                <span className="noValue">-</span>
                              )}
                            </p>
                          </div>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Mobile</p>
                            <p className="lightBlueText largerTextWidth">
                              {this.state.giftCardDetails.mobile ? (
                                this.state.giftCardDetails.mobile
                              ) : (
                                <span className="noValue">-</span>
                              )}
                            </p>
                          </div>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Amount</p>
                            <p className="lightBlueText">
                              $
                              {this.state.giftCardDetails.amount ? (
                                this.state.giftCardDetails.amount
                              ) : (
                                <span className="noValue">-</span>
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="checkoutBottomSection">
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Estimated Time of Service</p>
                      <p className="lightBlueText">
                        {this.state.estimatedTime ? (
                          `From ${this.state.estimatedTime}min`
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    {this.state.selectedBundle !== null && (
                      <div className="summaryDetailText">
                        <p className="lightBlueText leftAlign">
                          Bundle Discount
                        </p>
                        <p className="lightBlueText">
                          From $
                          {Number(
                            this.state.selectedBundle.bundle.originalPrice
                          ) -
                            Number(this.state.selectedBundle.bundle.salePrice)}
                        </p>
                      </div>
                    )}
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Estimated Total</p>
                      <p className="lightBlueText">
                        {this.state.estimatedTotal ? (
                          `$${this.state.estimatedTotal}`
                        ) : (
                          <span className="noValue">-</span>
                        )}
                      </p>
                    </div>
                    {this.state.discount !== null &&
                      this.state.discount > 0 && (
                        <div className="summaryDetailText">
                          <p className="darkBlueText">Discount Applied</p>
                          <p className="lightBlueText">
                            {" "}
                            {this.state.discount ? (
                              `$${this.state.discount}`
                            ) : (
                              <span className="noValue">-</span>
                            )}
                          </p>
                        </div>
                      )}
                    {this.state.selectedSubscriptions !== null &&
                      this.state.selectedSubscriptions.length > 0 && (
                        <div className="summaryDetailText">
                          <p className="darkBlueText">Monthly Costs</p>
                          <p className="lightBlueText">
                            {" "}
                            {this.subscriptionTotal(
                              this.state.selectedSubscriptions
                            )}
                          </p>
                        </div>
                      )}
                    {servicePrice !== "" && servicePrice == "POA" && (
                      <p className="poaNoteText">
                        Price will be provided upon arrival
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.showBundle && (
          <BundleModal
            bundleDetails={this.state.bundleDetails}
            handleBundleModal={this.handleBundleModal}
            cartTotal={this.state.cartTotal}
            handleBundleDecline={this.handleBundleDecline}
            handleBundleAccept={this.handleBundleAccept}
          />
        )}
        {this.state.currentStep != 4 && (
          <div
            className={
              "bookingFooter " +
              (this.state.isUser == false && " footerOffline ")
            }
          >
            {this.state.currentStep < 4 && (
              <div>
                {window.innerWidth <= 820 && (
                  <div className="bookingProgressSection">
                    <div className="summaryBox">
                      <h2>
                        Quote summary{" "}
                        {window.innerWidth <= 820 && (
                          <span
                            className="showSummary"
                            onClick={() => this.handleShowSummaryMobile()}
                          >
                            <img
                              className={
                                !this.state.showSummary ? `` : "rotate"
                              }
                              src={Images.downArrow}
                              alt=""
                            />
                          </span>
                        )}
                      </h2>
                      {this.state.showSummary && (
                        <>
                          {this.state.hasDiscountCode ? null : this.state
                              .estimatedTotal >= 100 ? (
                            <p className="added-coupon">
                              $10 Coupon Added!{" "}
                              <span className="green-tick">
                                <img src={Images.greenTick} alt="" />
                              </span>
                            </p>
                          ) : (
                            <p>Order over $100 to recieve $10 off </p>
                          )}
                          {this.state.hasDiscountCode ? null : (
                            <div className="progressBarSection">
                              <DiscountProgressBar
                                type={"mobile"}
                                amount={`${
                                  (this.state.estimatedTotal / 100) * 100
                                }%`}
                              />
                            </div>
                          )}
                          <div className="yourDetailSection">
                            <h3>Your Details</h3>
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Location</p>
                              <p className="lightBlueText">
                                {this.state.selectedLocationName ? (
                                  this.state.selectedLocationName
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Vehicle type</p>
                              <p className="lightBlueText">
                                {this.state.selectedVehicleType ? (
                                  this.state.selectedVehicleType
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            <h3 className="servicesSectionHeading">
                              Your Services
                            </h3>
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Time of arrival</p>
                              <p className="lightBlueText">
                                {this.state.selectedBookingTime ? (
                                  this.state.selectedBookingTime
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Type</p>
                              <p className="lightBlueText">
                                {this.state.selectedBundle ? (
                                  this.state.selectedBundleServices
                                ) : this.state.selectedServiceType ? (
                                  this.state.selectedServiceType
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Extras</p>
                              <p className="lightBlueText">
                                {this.state.selectedExtrasNames.length > 0 ? (
                                  this.state.selectedExtrasNames.join(", ")
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Subscriptions</p>
                              {this.state.selectedSubscriptions !== null &&
                              this.state.selectedSubscriptions.length > 0 ? (
                                <p className="lightBlueText">
                                  {" "}
                                  {this.state.selectedSubscriptionNames.join(
                                    ", "
                                  )}
                                </p>
                              ) : (
                                <span className="noValue">-</span>
                              )}
                            </div>
                            {this.state.giftCardActive &&
                              this.state.giftCardDetails && (
                                <div>
                                  <h3 className="servicesSectionHeading">
                                    Gift Card Purchase Detail
                                  </h3>
                                  <div className="summaryDetailText">
                                    <p className="darkBlueText">Name on card</p>
                                    <p className="lightBlueText">{`${
                                      this.state.giftCardDetails.firstName
                                    } ${
                                      this.state.giftCardDetails.lastName
                                        ? this.state.giftCardDetails.lastName
                                        : ""
                                    }`}</p>
                                  </div>
                                  <div className="summaryDetailText">
                                    <p className="darkBlueText">Email</p>
                                    <p className="lightBlueText largerTextWidth">
                                      {this.state.giftCardDetails.email ? (
                                        this.state.giftCardDetails.email
                                      ) : (
                                        <span className="noValue">-</span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="summaryDetailText">
                                    <p className="darkBlueText">Mobile</p>
                                    <p className="lightBlueText largerTextWidth">
                                      {this.state.giftCardDetails.mobile ? (
                                        this.state.giftCardDetails.mobile
                                      ) : (
                                        <span className="noValue">-</span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="summaryDetailText">
                                    <p className="darkBlueText">Amount</p>
                                    <p className="lightBlueText">
                                      $
                                      {this.state.giftCardDetails.amount ? (
                                        this.state.giftCardDetails.amount
                                      ) : (
                                        <span className="noValue">-</span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="checkoutBottomSection">
                            <div className="summaryDetailText">
                              <p className="darkBlueText">
                                Estimated Time of Service
                              </p>
                              <p className="lightBlueText">
                                {this.state.estimatedTime ? (
                                  `From ${this.state.estimatedTime}min`
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            {this.state.selectedBundle !== null && (
                              <div className="summaryDetailText">
                                <p className="lightBlueText leftAlign">
                                  Bundle Discount
                                </p>
                                <p className="lightBlueText">
                                  From $
                                  {Number(
                                    this.state.selectedBundle.bundle
                                      .originalPrice
                                  ) -
                                    Number(
                                      this.state.selectedBundle.bundle.salePrice
                                    )}
                                </p>
                              </div>
                            )}
                            <div className="summaryDetailText">
                              <p className="darkBlueText">Estimated Total</p>
                              <p className="lightBlueText">
                                {this.state.estimatedTotal ? (
                                  `$${this.state.estimatedTotal}`
                                ) : (
                                  <span className="noValue">-</span>
                                )}
                              </p>
                            </div>
                            {this.state.discount !== null &&
                              this.state.discount > 0 && (
                                <div className="summaryDetailText">
                                  <p className="darkBlueText">
                                    Discount Applied
                                  </p>
                                  <p className="lightBlueText">
                                    {" "}
                                    {this.state.discount ? (
                                      `$${this.state.discount}`
                                    ) : (
                                      <span className="noValue">-</span>
                                    )}
                                  </p>
                                </div>
                              )}
                            {this.state.selectedSubscriptions !== null &&
                              this.state.selectedSubscriptions.length > 0 && (
                                <div className="summaryDetailText">
                                  <p className="darkBlueText">Monthly Costs</p>
                                  <p className="lightBlueText">
                                    {" "}
                                    {this.state.selectedSubscriptions ? (
                                      this.subscriptionTotal(
                                        this.state.selectedSubscriptions
                                      )
                                    ) : (
                                      <span className="noValue">-</span>
                                    )}
                                  </p>
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="buttonSection">
                  <button
                    className="blueBorderButton"
                    onClick={
                      this.state.currentStep == 1
                        ? () => this.goBackHistory()
                        : () => this.goBack()
                    }
                  >
                    {" "}
                    Go Back{" "}
                  </button>
                  <button
                    className="lightBlueButton"
                    disabled={disabledContinue}
                    onClick={() => this.goForward()}
                  >
                    {" "}
                    Continue{" "}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <BookingTimeModal
          locationAvailability={this.state.locationAvailability}
          availibilityInterval={this.state.availibilityInterval}
          resetAvailability={this.resetAvailability}
          handleSelectedTime={this.handleSelectedTime}
          selectedBookingTime={this.state.selectedBookingTime}
          selectedTime={this.state.selectedTime}
          openBookingModal={this.state.openBookingModal}
          toggleDateModal={this.toggleDateModal}
          selectedDate={this.selectedDate}
          updateSelectedService={() => this.updateSelectedService}
          discount={this.state.discount}
          location={this.state.selectedLocation}
        />
        {this.state.rewardModalShow && (
          <RewardModal
            handleRewardModal={this.handleRewardModal}
            title={this.state.rewardModalTitle}
            desc={this.state.rewardModalContent}
          />
        )}
        {this.state.showGiveaway && (
          <GiveawayModal
            handleClose={() =>
              this.setState({
                showGiveaway: false,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default withCookies(withAuth0(Booking));
