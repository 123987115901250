import React from "react";
import { api, googleEvent } from "../../api";
import * as Images from "../../themes/images";
import "./subscription.css";
import HeaderMobile from "../../components/headerMobile";
import Loading from "../../components/Loading";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      allSubscriptions: [],
      subscriptions: [],
      selectedPrepaidCardId: "",
      selectedPrepaidCardName: "",
      selectedPrepaidCardSize: "",
      selectedPrepaidCardDescription: "",
      selectedPrice: 0,
      selectedCadence: 0,
      selectedLocation: null,
      selectedPosLocation: null,
      locationArray: [],
      locations: [],
      showPurchase: false,
      userDetails: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        carRego: "",
      },
      isUser: "guest",
      errorField: {},
    };
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.selectPrepaidService = this.selectPrepaidService.bind(this);
    this.preSelectPrepaidService = this.preSelectPrepaidService.bind(this);
    this.purchasePrepaidCard = this.purchasePrepaidCard.bind(this);
    this.handleBookingInputs = this.handleBookingInputs.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
  }

  validate(values) {
    const errors = {};
    // const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!values.hasOwnProperty("firstName") || !values.firstName) {
      errors.firstName = "This field is required";
    }
    if (!values.hasOwnProperty("lastName") || !values.lastName) {
      errors.lastName = "This field is required";
    }
    if (!values.hasOwnProperty("email") || !values.email) {
      errors.email = "This field is required";
    }
    if (!values.hasOwnProperty("phone") || !values.phone) {
      errors.phone = "This field is required";
    }
    if (!values.hasOwnProperty("carRego") || !values.carRego) {
      errors.carRego = "This field is required";
    }

    this.setState({
      errorField: errors,
    });

    return errors;
  }

  handleBookingInputs(val) {
    val = val.target;
    this.setState((prevState) => {
      let userDetails = Object.assign({}, prevState.userDetails); // creating copy of state variable jasper
      userDetails[val.name] = val.value; // update the name property, assign a new value
      return { userDetails };
    });
  }

  async getSubscriptions() {
    let res = await api("subscription/all", "GET", null);
    if (res.status === 200) {
      console.log("res: ", res.data);
      let cards = [];
      for (let pc = 0; pc < res.data.subscriptions.length; pc++) {
        const element = res.data.subscriptions[pc];
        if (!cards.includes(element.name)) {
          cards.push(element.name);
        }
      }
      this.setState({
        allSubscriptions: res.data.subscriptions,
        subscriptions: cards,
        isLoading: false,
      });

      const queryParams = new URLSearchParams(window.location.search);
      const subscriptionSelected = queryParams.get("id");
      if (subscriptionSelected) {
        this.preSelectPrepaidService(
          subscriptionSelected,
          res.data.subscriptions
        );
      }
    } else {
      console.log(res);
    }
  }

  async getLocations() {
    let res = await api("location/pricing", "GET", null);
    if (res.status === 200) {
      if (res.data.success) {
        let locations = res.data.pricing.map(function (item, index) {
          return { label: item.seoName, id: item._id };
        });
        this.setState({
          locations: locations,
          locationArray: res.data.pricing,
        });
      }
    } else {
      console.log(res);
    }
  }

  handleSelectedLocation(value) {
    if (value) {
      let locationid = value.id;
      let locations = this.state.locationArray;
      for (let index = 0; index < locations.length; index++) {
        const element = locations[index];
        if (element._id === locationid) {
          this.setState({
            selectedLocation: element,
            selectedPosLocation: element.posId,
          });
          break;
        }
      }
    } else {
      this.setState({
        selectedLocation: "",
      });
    }
  }

  selectPrepaidService(e) {
    this.setState({
      selectedPrepaidCardName: e.target.value,
    });

    for (let ss = 0; ss < this.state.allSubscriptions.length; ss++) {
      const element = this.state.allSubscriptions[ss];
      if (element.name == e.target.value) {
        this.setState({
          selectedPrepaidCardId: element._id,
          selectedPrice: element.price,
          selectedCadence: element.cadence.replace("ly", ""),
          selectedPrepaidCardDescription: element.description,
        });
      }
    }
    this.setState({
      showPurchase: true,
    });
  }

  preSelectPrepaidService(id, subscriptions) {
    for (let ss = 0; ss < subscriptions.length; ss++) {
      const element = subscriptions[ss];
      if (element.iPosId == id) {
        this.setState({
          selectedPrepaidCardName: element.name,
          selectedPrepaidCardId: element._id,
          selectedPrice: element.price,
          selectedCadence: element.cadence.replace("ly", ""),
          selectedPrepaidCardDescription: element.description,
        });
      }
    }
    this.setState({
      showPurchase: true,
    });
  }

  async purchasePrepaidCard() {
    let errors = this.validate(this.state.userDetails);

    const queryParams = new URLSearchParams(window.location.search);
    let utmSource = queryParams.get("utm_source");
    let utmMedium = queryParams.get("utm_medium");
    let utmCampaign = queryParams.get("utm_campaign");

    var utmData = {
      utmSource: utmSource,
      utmMedium: utmMedium,
      utmCampaign: utmCampaign,
    };

    if (Object.keys(errors).length === 0) {
      this.setState({
        isLoading: true,
      });

      try {
        const resp = await api(
          `subscription/purchase/${this.state.selectedPrepaidCardId}`,
          "POST",
          {
            userRego: this.state.userDetails.carRego,
            userDetails: this.state.userDetails,
            location: this.state.selectedPosLocation,
            utmData: utmData,
          }
        );
        console.log(resp);
        if (resp.status == 200) {
          window.location.href = resp.data.stripeUrl;
        }
        this.setState({
          isLoading: false,
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  componentDidMount() {
    this.getSubscriptions();
    this.getLocations();
  }

  render() {
    let parentThis = this;
    console.log(parentThis.state.errorField);
    return (
      <div className="prepaidCardPage subscriptionCardPage">
        {window.innerWidth < 767 && <HeaderMobile />}
        <div className="prepaidBanner"></div>
        <div className="bookingHeader">
          <div className="logoSection">
            <a href="https://conciergecarwash.com.au">
              <img src={Images.ccwLogoLight} className="ccwLogo" alt="Logo" />
            </a>
          </div>
          <div className="titleSection">
            <h1>Purchase a Car Wash Subscription</h1>
          </div>
          <div className="closeButtonSection"></div>
        </div>
        {parentThis.state.isLoading ? (
          <Loading />
        ) : (
          <div className="prepaidContainer">
            <div
              className={
                "inputsSection displayCenter " +
                (parentThis.state.isUser === false &&
                  " locationDetailSectionOffline")
              }
            >
              <h2>Please enter your details</h2>
              <div className="subscriptionUserDetails">
                <div className="flexRow">
                  <div className="twoColumn">
                    <input
                      type={"text"}
                      name={"firstName"}
                      id={"bookingFirstName"}
                      placeholder={"First name"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.firstName
                      }
                    />
                    {parentThis.state.errorField.firstName ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className="twoColumn">
                    <input
                      type={"text"}
                      name={"lastName"}
                      id={"bookingLastName"}
                      placeholder={"Last name"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.lastName
                      }
                    />
                    {parentThis.state.errorField.lastName ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.lastName}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"text"}
                      name={"phone"}
                      id={"bookingPhone"}
                      placeholder={"Phone"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.phone
                      }
                    />
                    {parentThis.state.errorField.phone ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.phone}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"email"}
                      name={"email"}
                      id={"bookingEmail"}
                      placeholder={"Email address"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.email
                      }
                    />
                    {parentThis.state.errorField.email ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"text"}
                      name={"carRego"}
                      id={"bookingRego"}
                      placeholder={"Car rego number"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.carRego
                      }
                    />
                    {parentThis.state.errorField.carRego ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.carRego}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <h2>Select a location</h2>
              <div className="inputArea">
                <Autocomplete
                  disablePortal
                  id="locationsInput"
                  options={this.state.locations}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a location" />
                  )}
                  onChange={(event, newValue) => {
                    this.handleSelectedLocation(newValue);
                  }}
                />
              </div>
              <h2>Select a subscription</h2>
              <select
                className="subscriptionSelect"
                id="subscriptionItems"
                onChange={parentThis.selectPrepaidService}
                value={parentThis.state.selectedPrepaidCardName}
              >
                <option value="" disabled selected>
                  Select a subscription
                </option>
                {parentThis.state.subscriptions.length > 0 &&
                  parentThis.state.subscriptions.map(function (i, index) {
                    return (
                      <option key={index} value={i}>
                        {i}
                      </option>
                    );
                  })}
              </select>
              {parentThis.state.showPurchase && (
                <div className="selectedServiceSection displayCenter">
                  <p className="selectedSericeText">{`${parentThis.state.selectedPrepaidCardName} | $${parentThis.state.selectedPrice}/${parentThis.state.selectedCadence}`}</p>
                  <p className="selectedServiceDesc">{`${parentThis.state.selectedPrepaidCardDescription}`}</p>
                  <button
                    className="prepaidCardButtonPurchase"
                    onClick={() => parentThis.purchasePrepaidCard()}
                  >
                    Purchase Subscription
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Subscription;
