import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { api } from "../../api";
import { CardOption, ToggleSwitch, PricingCard } from "../../components";
import './style.css';

class Maintenance extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="maintenancePage">
                <div className="maintenanceTitle">
                    <h1>Booking System is currently under maintenance. We will be back shortly.</h1>
                </div>
            </div>
        )
    }
}

export default Maintenance;