import React from "react";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CustomProgressBar from "../progressBar";
import * as Images from "../../themes/images";
const stripePromise = loadStripe(
  "pk_test_51J66hXJYg37oJgwIHM4ROU4wRB1SNZuOs6DoeFcIKPMUWt8lQaLAq2rBsjURZMaIYQtzVVopfeo4PYwupM4mA6Lf00PtPJLgg3"
);

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingUserDetails: props.bookingUserDetails,
      gEvent: null,
      isSubmit: false,
      errorField: {},
      isError: false,
    };
    this.handleBookingInputs = this.handleBookingInputs.bind(this);
    this.handleBlurInputs = this.handleBlurInputs.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.sendUserDetails = this.sendUserDetails.bind(this);
    this.removeWhieteSpace = this.removeWhieteSpace.bind(this);
  }

  componentDidMount() {
    this.props.googleEvent(
      this.props.ReactGA,
      this.props.GTM,
      "confirmation_page_view",
      this.props.isUser
    );
  }

  validate = (values) => {
    const errors = {};
    // const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!values.hasOwnProperty("firstName") || !values.firstName) {
      errors.firstName = "This field is required";
    }
    if (!values.hasOwnProperty("lastName") || !values.lastName) {
      errors.lastName = "This field is required";
    }
    if (!values.hasOwnProperty("email") || !values.email) {
      errors.email = "This field is required";
    }
    if (!values.hasOwnProperty("phone") || !values.phone) {
      errors.phone = "This field is required";
    }
    if (!values.hasOwnProperty("carRego") || !values.carRego) {
      errors.carRego = "This field is required";
    }
    return errors;
  };

  handleBookingInputs(val) {
    clearTimeout(this.state.gEvent);
    val = val.target;
    this.setState((prevState) => {
      let bookingUserDetails = Object.assign({}, prevState.bookingUserDetails);
      // console.log("val name:  ", val.name);
      if (val.name == "phone" || val.name == "carRego") {
        bookingUserDetails[val.name] = val.value.replaceAll(" ", "");
      } else {
        bookingUserDetails[val.name] = val.value;
      }
      return { bookingUserDetails };
    });
    let gEvent = setTimeout(() => {
      // this.props.handleBookingUserDetails(this.state.bookingUserDetails);
      this.props.googleEvent(
        this.props.ReactGA,
        this.props.GTM,
        "user_details",
        {
          firstName: this.state.bookingUserDetails.firstName,
          lastName: this.state.bookingUserDetails.lastName,
          mobile: this.state.bookingUserDetails.mobile,
          email: this.state.bookingUserDetails.email,
          carRego: this.state.bookingUserDetails.carRego,
        }
      );
    }, 2000);

    this.setState({
      gEvent: gEvent,
    });
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/.test(email)) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  handleBlurInputs() {
    this.props.handleBookingUserDetails(this.state.bookingUserDetails);
  }

  removeWhieteSpace(val) {
    val = val.target;
    this.setState((prevState) => {
      let bookingUserDetails = Object.assign({}, prevState.bookingUserDetails); // creating copy of state variable jasper
      bookingUserDetails[val.name] = val.value.replaceAll(" ", ""); // update the name property, assign a new value
      return { bookingUserDetails };
    });
  }

  async sendUserDetails(details) {
    let result = await this.props.handleBookingUserDetails(details);
    return result;
  }

  async handleSubmit() {
    this.setState({
      errorField: this.validate(this.state.bookingUserDetails),
    });
    if (this.validateEmail(this.state.bookingUserDetails.email) == false) {
      return;
    }

    try {
      let userDetails = await this.sendUserDetails(
        this.state.bookingUserDetails
      );
      if (userDetails) {
        this.setState({ isSubmit: true });
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  componentDidUpdate() {
    if (
      Object.keys(this.state.errorField).length === 0 &&
      this.state.isSubmit
    ) {
      this.props.confirmBooking();
    }
  }
  render() {
    return (
      <div className="confirmationStep">
        <h2>Arrival time</h2>
        <div className="flexRow bookingTimeSection">
          <div className="flexColumn">
            <p className="bookingTimeTextCheck">
              Is your booking date correct?
            </p>
            <p className="bookingTimeSelect">
              {this.props.selectedBookingTime}
            </p>
          </div>
          <button
            className="lightBlueButton"
            onClick={() => this.props.updateBookingTime()}
          >
            Update
          </button>
        </div>
        <h2 className="confirmationHeadings">About you</h2>
        <div className="aboutYouSection">
          <div className="flexRow">
            <div className="twoColumn">
              <input
                type={"text"}
                name={"firstName"}
                id={"bookingFirstName"}
                placeholder={"First name"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.firstName
                }
              />
              {this.state.errorField.firstName ? (
                <p className="errorMess">{this.state.errorField.firstName}</p>
              ) : null}
            </div>
            <div className="twoColumn">
              <input
                type={"text"}
                name={"lastName"}
                id={"bookingLastName"}
                placeholder={"Last name"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.lastName
                }
              />
              {this.state.errorField.lastName ? (
                <p className="errorMess">{this.state.errorField.lastName}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"phone"}
                id={"bookingPhone"}
                placeholder={"Phone"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.phone
                }
              />
              {this.state.errorField.phone ? (
                <p className="errorMess">{this.state.errorField.phone}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"email"}
                name={"email"}
                id={"bookingEmail"}
                placeholder={"Email address"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                onKeyUp={this.removeWhieteSpace}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.email
                }
              />
              {this.state.errorField.email ? (
                <p className="errorMess">{this.state.errorField.email}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"carRego"}
                id={"bookingRego"}
                autoComplete={"bookingRego"}
                placeholder={"Car rego number"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                onKeyUp={this.removeWhieteSpace}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.carRego
                }
              />
              {this.state.errorField.carRego ? (
                <p className="errorMess">{this.state.errorField.carRego}</p>
              ) : null}
            </div>
          </div>
        </div>
        <h2 className="confirmationHeadings">Confirm your booking</h2>
        <div className="flexColumn confirmSection">
          <p>
            <b>
              <span className="lightBlueText">
                A $2 booking fee will be taken to secure your booking.
              </span>
            </b>{" "}
            The $2 booking fee will be deducted at the time of checkout on
            premises.
          </p>
          <p>
            We understand your plans may change. If you need to cancel your
            booking, it will result in the forfeit of the booking fee.{" "}
            <b>
              <span className="lightBlueText">
                If you do not cancel your booking and do not show to an
                appointment, an additional $50 charge will occur.
              </span>
            </b>
          </p>
          <p className="note">
            <b>Note:</b> The total price is an estimate, and an exact quote will
            be given on the day. Prices vary depending on the state, size and
            condition of your vehicle.
          </p>
          <p className="note">
            <b>Note:</b> We will do our best to facilitate your reservation
            time; however, at times, delays can occur. The team will inform you
            of the estimated completion time.
          </p>
          <div className="buttonSection">
            {window.innerWidth <= 820 && (
              <div className="bookingProgressSection">
                <div className="summaryBox">
                  <h2>Quote summary</h2>
                  {this.props.discount ? null : this.props.estimatedTotal >=
                    100 ? (
                    <p className="added-coupon">
                      $10 Coupon Added!{" "}
                      <span className="green-tick">
                        <img src={Images.greenTick} alt="" />
                      </span>
                    </p>
                  ) : (
                    <p>Order over $100 to recieve $10 off </p>
                  )}
                  <div className="progressBarSection">
                    {this.props.discount ? null : (
                      <CustomProgressBar progress={this.props.progress} />
                    )}
                  </div>
                  <div className="yourDetailSection">
                    <h3>Your Details</h3>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Location</p>
                      <p className="lightBlueText">
                        {this.props.selectedLocationName}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Vehicle type</p>
                      <p className="lightBlueText">
                        {this.props.selectedVehicleType}
                      </p>
                    </div>
                    <h3 className="servicesSectionHeading">Your Services</h3>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Time of arrival</p>
                      <p className="lightBlueText">
                        {this.props.selectedBookingTime}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Type</p>
                      <p className="lightBlueText">
                        {this.props.selectedBundle
                          ? this.props.selectedBundleServices
                          : this.props.selectedServiceType}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Extras</p>
                      <p className="lightBlueText">
                        {this.props.selectedExtrasNames.join(", ")}
                      </p>
                    </div>
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Subscriptions</p>
                      {this.props.selectedSubscriptions !== null &&
                        this.props.selectedSubscriptions.length > 0 && (
                          <p className="lightBlueText">
                            {" "}
                            {this.props.selectedSubscriptionNames.join(", ")}
                          </p>
                        )}
                    </div>
                    {this.props.giftCardActive &&
                      this.props.giftCardDetails && (
                        <div>
                          <h3 className="servicesSectionHeading">
                            Gift Card Purchase Detail
                          </h3>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Name on card</p>
                            <p className="lightBlueText">{`${
                              this.props.giftCardDetails.firstName
                            } ${
                              this.props.giftCardDetails.lastName
                                ? this.props.giftCardDetails.lastName
                                : ""
                            }`}</p>
                          </div>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Email</p>
                            <p className="lightBlueText largerTextWidth">
                              {this.props.giftCardDetails.email}
                            </p>
                          </div>
                          <div className="summaryDetailText">
                            <p className="darkBlueText">Amount</p>
                            <p className="lightBlueText">
                              ${this.props.giftCardDetails.amount}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="checkoutBottomSection">
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Estimated Time</p>
                      <p className="lightBlueText">
                        {this.props.estimatedTime &&
                          `From ${this.props.estimatedTime}min`}
                      </p>
                    </div>
                    {this.props.selectedBundle !== null && (
                      <div className="summaryDetailText">
                        <p className="lightBlueText leftAlign">
                          Bundle Discount
                        </p>
                        <p className="lightBlueText">
                          From $
                          {Number(
                            this.props.selectedBundle.bundle.originalPrice
                          ) -
                            Number(this.props.selectedBundle.bundle.salePrice)}
                        </p>
                      </div>
                    )}
                    <div className="summaryDetailText">
                      <p className="darkBlueText">Estimated Total</p>
                      <p className="lightBlueText">
                        {this.props.estimatedTotal &&
                          `$${this.props.estimatedTotal}`}
                      </p>
                    </div>
                    {this.props.discount !== null &&
                      this.props.discount > 0 && (
                        <div className="summaryDetailText">
                          <p className="darkBlueText">Discount Applied</p>
                          <p className="lightBlueText">
                            {" "}
                            {this.props.discount ? (
                              `$${this.props.discount}`
                            ) : (
                              <span className="noValue">-</span>
                            )}
                          </p>
                        </div>
                      )}
                    {this.props.selectedSubscriptions !== null &&
                      this.props.selectedSubscriptions.length > 0 && (
                        <div className="summaryDetailText">
                          <p className="darkBlueText">Monthly Costs</p>
                          <p className="lightBlueText">
                            {" "}
                            {this.props.subscriptionTotal(
                              this.props.selectedSubscriptions
                            )}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
            <div className="confirmationButton">
              <button
                className="blueBorderButton"
                onClick={() => this.props.goBack()}
              >
                {" "}
                Go Back{" "}
              </button>
              <button
                className="lightBlueButton"
                onClick={() => this.handleSubmit()}
              >
                {" "}
                Confirm Booking{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirmation;
