import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { api } from "../../api";
import { CardOption, ToggleSwitch, PricingCard } from "../../components";
import './pricing.css';

class Pricing extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            locationArray: [],
            locations: [],
            selectedLocation: "",
            serviceOptions: [
                {
                    name:"Car Wash",
                    isSelected: true
                },
                {
                    name: "Wash & Detail",
                    isSelected: false
                }
            ],
            carTypeOptions: [
                {
                    name:"Sedan",
                    isSelected: true
                },
                {
                    name: "Wagon / SUV",
                    isSelected: false
                },
                {
                    name: "4WD / 7 Seater",
                    isSelected: false
                }
            ],
            locationTime: true
        }
        this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
        this.changeServiceSelected = this.changeServiceSelected.bind(this);
        this.changeTypeSelected = this.changeTypeSelected.bind(this);
        this.bookNow = this.bookNow.bind(this);

    }

    async getLocations(){
        let res = await api("location/pricing", 'GET', null);
        if(res.status === 200){
            if(res.data.success){
               let locations = res.data.pricing.map(function(item, index){
                   return { label: item.seoName, id: item._id}
               });
               this.setState({
                   locations: locations,
                   locationArray: res.data.pricing,
               })
            }
        } else {
            console.log(res);
        }
    }

    handleSelectedLocation(value){
        if(value){
            let locationid = value.id;
            let locations = this.state.locationArray;
            for (let index = 0; index < locations.length; index++) {
                const element = locations[index];
                if(element._id === locationid){
                    this.setState({
                        selectedLocation: element
                    });
                    break; 
                }
            }
        } else {
            this.setState({
                selectedLocation: ""
            });
        }
        
    }

    changeServiceSelected(value){
        this.setState(prevState => ({
            serviceOptions: prevState.serviceOptions.map(
                obj => (obj.name === value ? Object.assign(obj, {isSelected: true}) : Object.assign(obj, {isSelected: false}))
            )
        }));
    }
    
    changeTypeSelected(value){
        this.setState(prevState => ({
            carTypeOptions: prevState.carTypeOptions.map(
                obj => (obj.name === value ? Object.assign(obj, {isSelected: true}) : Object.assign(obj, {isSelected: false}))
            )
        }));
    }

    toggleTime(e){
        this.setState({
            locationTime: e.target.checked
        });
    }

    bookNow(id){
        // console.log("book with service: ", id)
    }

    componentDidMount(){
        this.getLocations();
    }

    render(){
        let parentThis = this;
        const pricingOrder = ["Express Wash", "Economy Wash", "Premium Wash", "Super Wash", "Hand Polish"];
        const sortByObject = pricingOrder.reduce((obj, item, index) => {
            return{
                ...obj,
                [item]: index,
            }
        }, {});

        
        let pricingOptions = [];
        if(this.state.selectedLocation.pricing){
            pricingOptions = this.state.selectedLocation.pricing.sort((a,b) => sortByObject[a.name] - sortByObject[b.name]);
        }
        
        return(
            <div className="pricingPage">
                <div className="locationInputSection">
                    <h1>Show Pricing For</h1>
                    <div className="inputArea">
                        <Autocomplete
                            disablePortal
                            id="locationsInput"
                            options={this.state.locations}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Select a location" />}
                            onChange={(event, newValue) => {
                                this.handleSelectedLocation(newValue);
                            }}
                        />
                        <div className="locationViewButton">
                            <p>View</p>
                        </div>
                    </div>
                </div>
                
                {
                    this.state.selectedLocation !== "" ? (
                        <div className="pricingArea">
                            <div className="pricingDetailSection">
                                <div className="vehicleTypeSection">
                                    <h4>Choose your vehicle type</h4>
                                    <div className="carTypeOptions">
                                    {
                                        this.state.carTypeOptions.map(function(item){
                                            return <CardOption 
                                                item={item} 
                                                select={(item) => parentThis.changeTypeSelected(item)}
                                            />
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="serviceTypeSection">
                                    <h4>Select your service type</h4>
                                    <div className="serviceTypeOptions">
                                    {
                                        this.state.selectedLocation !== "" && (
                                            this.state.serviceOptions.map(function(item){
                                                return <CardOption 
                                                    item={item} 
                                                    select={(item) => parentThis.changeServiceSelected(item)}
                                                />
                                            })
                                        
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="pricingSection">
                                <h2>Car Wash Pricing</h2>
                                <p>Drive ins always available</p>
                                <div className="toggleArea">
                                    <p className={"firstTimeText " + (this.state.locationTime === false && "textActive") } >9am - 12pm</p>
                                    <ToggleSwitch onClick={(e) => this.toggleTime(e)} />
                                    <p className={"secondTimeText " + (this.state.locationTime && "textActive")}>1pm - 6pm</p>
                                </div>
                                <div className="pricingGrid">
                                {
                                    pricingOptions.map(function(item){
                                        var selectedService, selectedType = "";
                                        for (let st = 0; st < parentThis.state.serviceOptions.length; st++) {
                                            const element = parentThis.state.serviceOptions[st];
                                            if(element.isSelected){
                                                selectedService = element.name;
                                            }
                                        }
                                        for (let st = 0; st < parentThis.state.carTypeOptions.length; st++) {
                                            const element = parentThis.state.carTypeOptions[st];
                                            if(element.isSelected){
                                                selectedType = element.name;
                                            }
                                        }
                                        if(item.serviceType[0].name === selectedService){
                                            if(parentThis.state.locationTime == false){
                                                if(item.timeOfDay[0].startTimeHour === 8 && item.timeOfDay[0].endTimeHour === 13){
                                                    return <PricingCard 
                                                        item={item}
                                                        selectedType={selectedType}
                                                        bookNow={() => parentThis.bookNow(item._id)}
                                                    />
                                                }
                                            } else {
                                                if(item.timeOfDay[0].startTimeHour !== 8 && item.timeOfDay[0].endTimeHour !== 13){
                                                    return <PricingCard 
                                                        item={item}
                                                        selectedType={selectedType}
                                                        bookNow={() => parentThis.bookNow(item._id)}
                                                    />
                                                }
                                            }
                                        }
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="selectLocationInfoSection">
                            <p>Please select a location before continuing...</p>
                        </div>
                    )
                }
                <div className="optionalExtrasSection">
                    <h2>Optional Extras</h2>
                    <div className="extrasSectionArea">
                        <ul className="extrasSectionList">
                            <li className="extraItems">Interior trim upgrade:   <b>From $20</b></li>
                            <li className="extraItems">Headlight Restoration:   <b>From $25</b></li>
                            <li className="extraItems">Wheel Bling (Detail):    <b>From $25</b></li>
                            <li className="extraItems">Seat or Mat Steam Clean: <b>From $30</b></li>
                            <li className="extraItems">Leather Treatment:       <b>From $70</b></li>
                            <li className="extraItems">Hand Wax & Clay Bar:     <b>From $60</b></li>
                            <li className="extraItems">Machine Cut & Polish:    <b>From $150</b></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pricing;