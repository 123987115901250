import React from "react";
import Box from '@mui/material/Box';
import * as Images from "../themes/images";
import ExtraExampleModal from "./extraExampleModal";

// import * as Images from "../themes/images";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    maxWidth: "60%",
    maxHeight: "80%",
};


class ExtraItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        let isSelected = false;
        if(this.props.selectedExtrasNames.includes(this.props.extra.name)){
            isSelected = true;
        }
        return(
            <div className={"extraItem " + (isSelected ? "activeItem" : "") } key={this.props.index} >
                {
                    isSelected ? (
                        <img className="isActive checkIcon" src={Images.blueTick} alt="Checked" onClick={() => this.props.selectExtra()} />
                    ) : (
                        <img src={Images.unChecked} className="uncheckedIcon" alt="Unchecked" onClick={() => this.props.selectExtra()} />
                    )
                }
                <div className="extraContentWrapper">
                    <div className="extraName" onClick={() => this.props.selectExtra()}>
                        <p className="extraOptionTitle">{this.props.extra.name}</p>
                        <div className="durationSection">
                            <img src={Images.greyClockIcon} className="durationIcon" alt="duration" />
                            <p className="durationFrom">{this.props.extra.durationInMinutes}min</p>
                        </div>
                    </div>
                    <div className="extraExamAndPrice">
                        <div className="extraExample">
                            <ExtraExampleModal 
                                extra={this.props.extra} 
                                selectExtra={() => this.props.selectExtra()}
                                isSelected={isSelected}
                            />
                        </div>
                        <div onClick={() => this.props.selectExtra()}>
                            <p className="extraPrice pricingCardText">${this.props.extra.price}</p>
                        </div>
                    </div>
                </div>
            </div>                
        )
        
    }
}

export default ExtraItem;