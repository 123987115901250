import React from "react";
import { api, googleEvent } from "../../api";
import * as Images from "../../themes/images";
import "./prepaid.css";
import HeaderMobile from "../../components/headerMobile";
import Loading from "../../components/Loading";

class Prepaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      allCardDetails: [],
      prepaidCards: [],
      carSizes: [],
      selectedPrepaidCardId: "",
      selectedPrepaidCardName: "",
      selectedPrepaidCardSize: "",
      selectedPrice: 0,
      hasVehicleType: [
        "Economy Wash",
        "Express Wash",
        "Premium Wash",
        "Super Wash",
      ],
      showPurchase: false,
      userDetails: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        carRego: "",
      },
      isUser: "guest",
      errorField: {},
    };
    this.getPrepaidCards = this.getPrepaidCards.bind(this);
    this.selectPrepaidService = this.selectPrepaidService.bind(this);
    this.selectPrepaidSize = this.selectPrepaidSize.bind(this);
    this.checkSelectVals = this.checkSelectVals.bind(this);
    this.purchasePrepaidCard = this.purchasePrepaidCard.bind(this);
    this.updateRegoVal = this.updateRegoVal.bind(this);
    this.handleBookingInputs = this.handleBookingInputs.bind(this);
    this.validate = this.validate.bind(this);
  }

  validate(values) {
    const errors = {};
    // const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!values.hasOwnProperty("firstName") || !values.firstName) {
      errors.firstName = "This field is required";
    }
    if (!values.hasOwnProperty("lastName") || !values.lastName) {
      errors.lastName = "This field is required";
    }
    if (!values.hasOwnProperty("email") || !values.email) {
      errors.email = "This field is required";
    }
    if (!values.hasOwnProperty("phone") || !values.phone) {
      errors.phone = "This field is required";
    }
    if (!values.hasOwnProperty("carRego") || !values.carRego) {
      errors.carRego = "This field is required";
    }

    this.setState({
      errorField: errors,
    });

    return errors;
  }

  handleBookingInputs(val) {
    val = val.target;
    this.setState((prevState) => {
      let userDetails = Object.assign({}, prevState.userDetails); // creating copy of state variable jasper
      userDetails[val.name] = val.value; // update the name property, assign a new value
      return { userDetails };
    });
  }

  async getPrepaidCards() {
    let res = await api("prepaid/getPrepaidCards", "GET", null);
    if (res.status === 200) {
      let cards = [];
      let sizes = [];
      for (let pc = 0; pc < res.data.prepaidCards.length; pc++) {
        const element = res.data.prepaidCards[pc];
        if (!cards.includes(element.productName)) {
          cards.push(element.productName);
        }
        if (!sizes.includes(element.size)) {
          sizes.push(element.size);
        }
      }
      this.setState({
        allCardDetails: res.data.prepaidCards,
        prepaidCards: cards,
        carSizes: sizes,
        isLoading: false,
      });
    } else {
      console.log(res);
    }
  }

  selectPrepaidService(e) {
    this.setState({
      selectedPrepaidCardName: e.target.value,
      selectedPrepaidCardSize: "",
    });

    if (!this.state.hasVehicleType.includes(e.target.value)) {
      for (let ss = 0; ss < this.state.allCardDetails.length; ss++) {
        const element = this.state.allCardDetails[ss];
        if (element.productName == e.target.value) {
          this.setState({
            selectedPrepaidCardId: element._id,
            selectedPrice: element.price,
          });
        }
      }
      this.setState({
        showPurchase: true,
      });
    } else {
      this.setState({
        selectPrepaidSize: "",
        showPurchase: false,
      });
    }
  }

  selectPrepaidSize(e) {
    this.setState({
      selectedPrepaidCardSize: e,
    });
    if (
      this.state.hasVehicleType.includes(this.state.selectedPrepaidCardName)
    ) {
      for (let ss = 0; ss < this.state.allCardDetails.length; ss++) {
        const element = this.state.allCardDetails[ss];
        if (
          element.size == e &&
          element.productName == this.state.selectedPrepaidCardName
        ) {
          this.setState({
            selectedPrepaidCardId: element._id,
            selectedPrice: element.price,
          });
        }
      }
      this.setState({
        showPurchase: true,
      });
    }
  }

  checkSelectVals() {
    if (
      this.state.hasVehicleType.includes(this.state.selectedPrepaidCardName)
    ) {
      if (this.state.selectedPrepaidCardSize !== "") {
        this.setState({
          showPurchase: true,
        });
      }
    } else {
      if (this.state.selectedPrepaidCardName !== "") {
        this.setState({
          showPurchase: true,
        });
      }
    }
  }

  updateRegoVal(e) {
    this.setState({
      userRego: e.target.value,
    });
  }

  async purchasePrepaidCard() {
    let errors = this.validate(this.state.userDetails);
    console.log(errors);

    if (Object.keys(errors).length === 0) {
      this.setState({
        isLoading: true,
      });

      try {
        const resp = await api(
          `prepaid/purchase/${this.state.selectedPrepaidCardId}`,
          "POST",
          { userRego: this.state.userRego, userDetails: this.state.userDetails }
        );
        console.log(resp);
        if (resp.status == 200) {
          window.location.href = resp.data.stripeUrl;
        }
        this.setState({
          isLoading: false,
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  componentDidMount() {
    this.getPrepaidCards();
  }

  render() {
    let parentThis = this;
    console.log(parentThis.state.errorField);
    const sizeOrder = ["Sedan", "Wagon", "4wd"];
    const sortByObject = sizeOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    let sizeArray = [];
    sizeArray = parentThis.state.carSizes.map(function (item) {
      if (item !== "") {
        return item;
      }
    });
    sizeArray = sizeArray.sort((a, b) => sortByObject[a] - sortByObject[b]);

    return (
      <div className="prepaidCardPage">
        {window.innerWidth < 767 && <HeaderMobile />}
        <div className="prepaidBanner"></div>
        <div className="bookingHeader">
          <div className="logoSection">
            <a href="https://conciergecarwash.com.au">
              <img src={Images.ccwLogoLight} className="ccwLogo" alt="Logo" />
            </a>
          </div>
          <div className="titleSection">
            <h1>Purchase a Prepaid Card</h1>
          </div>
          <div className="closeButtonSection"></div>
        </div>
        {parentThis.state.isLoading ? (
          <Loading />
        ) : (
          <div className="prepaidContainer">
            <div
              className={
                "inputsSection displayCenter " +
                (parentThis.state.isUser === false &&
                  " locationDetailSectionOffline")
              }
            >
              <h2>Please enter your details</h2>
              <div className="prepaidUserDetails">
                <div className="flexRow">
                  <div className="twoColumn">
                    <input
                      type={"text"}
                      name={"firstName"}
                      id={"bookingFirstName"}
                      placeholder={"First name"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.firstName
                      }
                    />
                    {parentThis.state.errorField.firstName ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className="twoColumn">
                    <input
                      type={"text"}
                      name={"lastName"}
                      id={"bookingLastName"}
                      placeholder={"Last name"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.lastName
                      }
                    />
                    {parentThis.state.errorField.lastName ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.lastName}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"text"}
                      name={"phone"}
                      id={"bookingPhone"}
                      placeholder={"Phone"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.phone
                      }
                    />
                    {parentThis.state.errorField.phone ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.phone}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"email"}
                      name={"email"}
                      id={"bookingEmail"}
                      placeholder={"Email address"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.email
                      }
                    />
                    {parentThis.state.errorField.email ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"text"}
                      name={"carRego"}
                      id={"bookingRego"}
                      placeholder={"Car rego number"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.carRego
                      }
                    />
                    {parentThis.state.errorField.carRego ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.carRego}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <h2>Select a prepaid card below</h2>
              <select
                className="prepaidSelect"
                id="prepaidService"
                onChange={parentThis.selectPrepaidService}
                value={parentThis.state.selectedPrepaidCardName}
              >
                <option value="" disabled selected>
                  Select a prepaid card
                </option>
                {parentThis.state.prepaidCards.length > 0 &&
                  parentThis.state.prepaidCards.map(function (i, index) {
                    return (
                      <option key={index} value={i}>
                        {i}
                      </option>
                    );
                  })}
              </select>
              {parentThis.state.hasVehicleType.includes(
                parentThis.state.selectedPrepaidCardName
              ) && (
                <div className="sizeContainer displayCenter">
                  <h2>Select vehicle type</h2>
                  <div className="carSizes">
                    {sizeArray.map(function (i, index) {
                      if (i != undefined) {
                        let image = "";
                        let name = "";
                        let active = "";

                        if (parentThis.state.selectedPrepaidCardSize == i) {
                          active = " active";
                        }

                        switch (i) {
                          case "Sedan":
                            name = "Sedan / Hatch";
                            image = Images.sedanIco;
                            break;
                          case "Wagon":
                            name = "SUV / Wagon";
                            image = Images.wagonIco;
                            break;
                          case "4wd":
                            name = "4WD / 7 Seater";
                            image = Images.fourWDIco;
                            break;
                          default:
                            break;
                        }
                        return (
                          <div
                            key={index}
                            className={"vehicleTypeCard" + active}
                            onClick={() => parentThis.selectPrepaidSize(i)}
                          >
                            <img src={image} alt={i + " icon"} />
                            <p>{name}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
              {parentThis.state.showPurchase && (
                <div className="selectedServiceSection displayCenter">
                  <p className="selectedSericeText">{`${
                    parentThis.state.selectedPrepaidCardName
                  } ${
                    parentThis.state.selectedPrepaidCardSize
                      ? " - " + parentThis.state.selectedPrepaidCardSize
                      : ""
                  } | $${parentThis.state.selectedPrice}`}</p>
                  <button
                    className="prepaidCardButtonPurchase"
                    onClick={() => parentThis.purchasePrepaidCard()}
                  >
                    Purchase Prepaid Card
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Prepaid;
