import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../../api";
import { CardOption, Auth0LoginButton } from "../../components/";
import Button from "@mui/material/Button";
import * as Images from "../../themes/images";

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationArray: [],
      locations: [],
      selectedLocation: "",
      carTypeOptions: [
        {
          name: "Sedan",
          isSelected: false,
        },
        {
          name: "Wagon / SUV",
          isSelected: false,
        },
        {
          name: "4WD / 7 Seater",
          isSelected: false,
        },
        {
          name: "RV / Caravan",
          isSelected: false,
        },
      ],
      selectedTime: "",
    };
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.changeTypeSelected = this.changeTypeSelected.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  async getLocations() {
    let res = await api("location/pricing", "GET", null);
    if (res.status === 200) {
      if (res.data.success) {
        let locations = res.data.pricing.map(function (item, index) {
          return { label: item.seoName, id: item._id };
        });
        this.setState({
          locations: locations,
          locationArray: res.data.pricing,
        });
      }
    } else {
      console.log("failed to get pricing: ", res);
    }
  }

  handleSelectedLocation(value) {
    if (value) {
      let locationid = value.id;
      let locations = this.state.locationArray;
      for (let index = 0; index < locations.length; index++) {
        const element = locations[index];
        if (element._id === locationid) {
          this.setState({
            selectedLocation: element,
          });
          this.props.handleSelectedLocation(element);
          break;
        }
      }
    } else {
      this.setState({
        selectedLocation: "",
      });
      this.props.handleSelectedLocation("");
    }
  }

  changeTypeSelected(value) {
    this.props.handleSelectedVehicleType(value);
    this.setState((prevState) => ({
      carTypeOptions: prevState.carTypeOptions.map((obj) =>
        obj.name === value
          ? Object.assign(obj, { isSelected: true })
          : Object.assign(obj, { isSelected: false })
      ),
    }));
  }

  continue() {
    this.props.handleUpdateUser("guest");
  }

  updateUser() {
    // console.log("get auth0 info here");
    //this.props.handleUpdateUser("guest");
  }

  componentDidMount() {
    this.getLocations();
    if (this.props.selectedVehicleType !== "") {
      this.setState((prevState) => ({
        carTypeOptions: prevState.carTypeOptions.map((obj) =>
          obj.name === this.props.selectedVehicleType
            ? Object.assign(obj, { isSelected: true })
            : Object.assign(obj, { isSelected: false })
        ),
      }));
    }
  }

  render() {
    let parentThis = this;

    return (
      <div className="detailsStep">
        {this.props.isUser === false ? (
          <div className="loginSection">
            <h3>Already have an account?</h3>
            <div className="buttonSection">
              <Auth0LoginButton updateUser={this.updateUser} />
              <button
                className="blueBorderButton"
                onClick={() => this.continue()}
              >
                Continue as guest
              </button>
            </div>
          </div>
        ) : this.props.isUser != "guest" ? (
          <div className="loggedInSection">
            <div className="profileSection">
              <p className="profileText">Logged in as: </p>
              <img
                src={this.props.bookingUserDetails.picture}
                className="userProfilePic"
              />
              <p className="profileText">{`${this.props.bookingUserDetails.firstName} ${this.props.bookingUserDetails.lastName}`}</p>
            </div>
          </div>
        ) : null}
        <div
          className={
            "locationDetailSection " +
            (this.props.isUser === false && " locationDetailSectionOffline")
          }
        >
          <h3>Carwash location</h3>
          <div className="inputArea">
            <Autocomplete
              disablePortal
              id="locationsInput"
              options={this.state.locations}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select a location" />
              )}
              onChange={(event, newValue) => {
                this.handleSelectedLocation(newValue);
              }}
              value={
                this.props.selectedLocationName !== ""
                  ? this.props.selectedLocationName
                  : ""
              }
            />
            <div className="locationViewButton">
              <p>Select</p>
            </div>
          </div>
          <div className="vehicleTypeSection">
            <h3>Choose your vehicle type</h3>
            <div className="carTypeOptions">
              {this.state.carTypeOptions.map(function (item) {
                return (
                  <CardOption
                    item={item}
                    select={(item) => parentThis.changeTypeSelected(item)}
                    key={item.name}
                  />
                );
              })}
            </div>
          </div>
          <div className="bookingTimeSection">
            <h3>Arrival Time</h3>
            <p className="bookingNote1">
              Note: Bookings within the hour aren't accepted
            </p>
            <Button
              className="bookingTimeButton"
              onClick={() =>
                this.props.openDateModal(!this.props.openBookingModal)
              }
            >
              <span>
                {this.props.selectedBookingTime
                  ? this.props.selectedBookingTime
                  : "Select your booking date and time"}
              </span>
              <img
                src={Images.calendarIco}
                className="calendarIco"
                alt="calendar"
              />
            </Button>
            <p className="bookingNote2">
              Please Note: Drive-in available any time during open hours
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
