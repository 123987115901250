import React, { Component } from "react";

class Step extends Component {
  constructor(props){
      super(props);
  }

  render() {
    let parentThis = this;
    return (
      <div className="bookingStepArea">
        {
          this.props.items.map(function(item, i){
            let selected = item.isSelected;
            let { cookies } = parentThis.props.cookie;
            if(item.name == "Details"){
              selected = cookies.get('detailsStep') ? cookies.get('detailsStep') == "completed" ? "completed" : cookies.get('detailsStep') == "false" ? false : true : item.isSelected;
            }
            if(item.name == "Service"){
              selected = cookies.get('serviceStep') ? cookies.get('serviceStep') == "completed" ? "completed" : cookies.get('serviceStep') == "false" ? false : true : item.isSelected;
            }
            if(item.name == "Extras"){
              selected = cookies.get('extrasStep') ? cookies.get('extrasStep') == "completed" ? "completed" : cookies.get('extrasStep') == "false" ? false : true : item.isSelected;
            }
            if(item.name == "Confirm Booking"){
              selected = cookies.get('confirmStep') ? cookies.get('confirmStep') == "completed" ? "completed" : cookies.get('confirmStep') == "false" ? false : true : item.isSelected;
            }
            return <div key={i} className={"bookingStep " + (selected == "completed" ? "completedStep" : selected == true && "activeStep")}>
              <p className="cardOptionTitle">{item.name}</p>
            </div>
          })
        }
      </div>
      
    );
  }
}

export default Step;