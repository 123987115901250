import React from "react";
import * as Images from "../themes/images";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DatePicker from "sassy-datepicker";
import moment from "moment";
import { TimeSelection } from "../components";
import { maxHeight } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3.5,
  maxWidth: window.innerWidth > 820 ? 700 : "95%",
  maxHeight: window.innerHeight > 700 ? "90%" : "90%",
  borderRadius: "50px",
  outline: 0,
};

class GiveawayModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.handleClose();
  }

  componentDidMount() {
    // console.log("show bundle modal: ", this.props.bundleDetails)
  }

  render() {
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.handleClose()}
        aria-labelledby="Reward Modal"
        aria-describedby="Rewards code applied"
        className="rewardModal popupModal"
      >
        <Box sx={style}>
          <div>
            <div style={{ textAlign: "center" }}>
              <h2 className="modalTitle">$10,000 Prize Giveaway!</h2>
              <img
                src={Images.GiveawayTime}
                alt="giveaway"
                style={{ width: "70%", marginTop: 15 }}
              />
              <p className="modalDesc">
                Once you complete this booking and your service at Concierge Car
                Wash you’ll automatically be entered in the draw to win.
              </p>
            </div>
            <div>
              <p className="noteText">
                Note: The offer is active only on this page, so do not navigate
                away from it until you complete your booking.
              </p>
            </div>
          </div>
          <div className="buttonSection">
            <button
              className="lightBlueButton"
              onClick={() => this.handleClose()}
            >
              {" "}
              Continue{" "}
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default GiveawayModal;
