import React from "react";
import * as Images from "../themes/images";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import DatePicker from 'sassy-datepicker';
import moment from "moment";
import { TimeSelection } from "../components";
import { maxHeight } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3.5,
    maxWidth: window.innerWidth > 820 ? 700 : "95%",
    maxHeight: window.innerHeight > 700 ? "80%" : "90%" ,
    borderRadius: "50px",
    outline: 0
};

class RewardModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: true,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        this.props.handleRewardModal();
    }

    componentDidMount(){
        // console.log("show bundle modal: ", this.props.bundleDetails)
    }

    render() {
        return (
            <Modal
                open={this.state.open}
                onClose={() =>  this.handleClose()}
                aria-labelledby="Reward Modal"
                aria-describedby="Rewards code applied"
                className="rewardModal popupModal"
            >
                <Box sx={style}>
                    <div className="rewardModalContent">
                        <div>
                            <h2 className="modalTitle">{this.props.title ? this.props.title : "Your discount code is now applied to this unique booking link!"}</h2>
                            <p className="modalDesc">{this.props.desc ? this.props.desc : "Continue to book your service on this link and the discount will automatically be reflected at checkout."}</p>
                        </div>
                        <div>
                            <p className="noteText">Note: Remain on this page when making a booking to ensure the discount code is applied. If you navigate away from this page. the discount code will not be applied.</p>
                        </div>
                    </div>
                    <div className="buttonSection">
                        <button className="lightBlueButton" onClick={() => this.handleClose()}> Continue </button>
                    </div>
                </Box>
            </Modal>
        )
    }
}

export default RewardModal;