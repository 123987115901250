import React from "react";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

export default function Loading() {
  return (
      <div className="LoadingSection">
        <PuffLoader color="#001571" size={150} />
      </div>
      
  );
}